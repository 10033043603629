/**
 * Built in LogOutput which will attempt to write a nicely formatted log entry to stdio
 * @module
 */
import { LogOutput } from '#@/logging/interfaces'
import { LogEntry } from '#@/logging/logEntry'
import pkg from 'date-and-time';
const { format: dateFormat } = pkg;
import { StackEntry }/* as i_*/ from '#@/logging/interfaces'
import { objFormat as nformat } from '#@/utils/inspection'

interface i_logOutput {
    colour: string;
    label: string;
    symbol: string;
}

const loglevels:i_logOutput[] = [
    { colour: "", label: "NONE", symbol: " " },
    { colour: "\x1B[1;31m", label: "FATAL", symbol: "\u{2620}\u{FE0F}" }, //Bright red
    { colour: "\x1B[0;31m", label: "ERROR", symbol: "\u{1F6A8}" }, //Red
    { colour: "\x1B[0;33m", label: "WARN ", symbol: "\u{26A0}\u{FE0F}" }, //Yellow
    { colour: "\x1B[0;32m", label: "INFO ", symbol: "\u{2139}\u{FE0F}" }, //green
    { colour: "\x1B[0;36m", label: "DEBUG", symbol: "\u{1F41C}" }, //blue
    { colour: "\x1B[0;35m", label: "TRACE", symbol: "\u{1F52C}" } //purple
];

/**
 * Built in LogOutput which will attempt to write a nicely formatted log entry to stdio
 */
export class LogOutputStdIO implements LogOutput {

    private _indent(what: string, wth: string): string {
        return what.replace(/^/gm, wth);
    }

    _traceFormat(trace: StackEntry[]): string {
        var os = "";
        for (var i = 0; i < trace.length; i += 1) {
            if (os !== "") os += "\n";
            os += "at " + trace[i].method + " (" + trace[i].module + ":" + trace[i].line + ":" + trace[i].column + ")";
        }
        return os;
    }

    public maxLevel:number = 4

    log(logEntry: LogEntry) {
        let stream:NodeJS.WriteStream = process.stdout;
        if (logEntry.level<3) {
            stream = process.stderr;
        }
        //console.log('dlo', logEntry.body)
        //if (level<=self.level) {
        if (logEntry.level>this.maxLevel) return;
        var ll = loglevels[logEntry.level];
        //var m = moment(logEntry.time);
        var ld = ll.symbol + " [" + dateFormat(logEntry.time, "HH:mm:ss.SSS") + "] " + ll.colour + ll.label + ": \x1B[0m" + logEntry.subject
        stream.write(ld + '\n');
        if (logEntry.route) stream.write(`   \x1B[0;34m${logEntry.route.join(' > ')}\x1B[0m \n`);
        if (logEntry.body) stream.write(this._indent(logEntry.body, '      ') + '\n');
        if (logEntry.data) {
            //if (logEntry.data instanceof Array) {
            stream.write('   Data...\n' + this._indent(nformat(...logEntry.data)/*nformat.apply(null, [[logEntry.data]])*/, "      ") + '\n');
            //} else {
            //  stream.write('   Data...\n'+this.indent(util.format.apply(util,logEntry.data),"      ") + '\n');
            //}
        }
        if (logEntry.stackTrace) stream.write('   Call Stack:\n' + this._indent(this._traceFormat(logEntry.stackTrace), "      ") + '\n');
        //console.log(arguments);
        //}
    }
}

/**
 * Generally you wont want to make your own so a premade logOutputStdIO is provided.
 */
const logOutputStdIO: LogOutputStdIO = new LogOutputStdIO();
export { logOutputStdIO as logOutputStdIO }
