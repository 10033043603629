import { ClientStub } from "#@/rpcSystem/stubs/clientStub";
import { RPCEndpoint } from '#@/rpcSystem/endpoints/endpoint';
import { Registry } from '#@/collections/registry';
import { TwoWayOTMMap } from '#@/collections/twoWayOTMMap';
import { TwoWayMap } from '#@/collections/twoWayMap';
import { IdMapper } from '#@/collections/idMapper';
import { IBindable as Bindable } from '#@/interfaces/bindable';
import { ITrunk } from "#@/interfaces/trunk";
import { EventMultiplexer } from '#@/rpcSystem/events/eventMultiplexer';
import { IListenerRegistration as ListenerRegistration } from '#@/rpcSystem/events/interfaces/listenerRegistration';
import { Messages } from '#@/rpcSystem/enums/messages';
import { IEventData as EventData } from '#@/interfaces/eventData';
import { IBindingData as BindingData } from '#@/rpcSystem/events/interfaces/bindingData';
import { IBindCallback as BindCallback } from '#@/rpcSystem/events/interfaces/bindCallback';
import { ICallbackData as CallbackData } from '#@/rpcSystem/events/interfaces/callbackData';
import { IEventBindingData as EventBindingData } from '#@/rpcSystem/events/interfaces/eventBindingData';
import { BindingState } from '#@/rpcSystem/enums/bindingState';
import { IEventEmitData as EventEmitData } from '#@/rpcSystem/events/interfaces/eventEmitData';
import { IInvocationMessageData as InvocationMessageData } from '#@/rpcSystem/events/interfaces/invocationMessageData';
import { IInvocationData as InvocationData } from "#@/rpcSystem/events/interfaces/invocationData";
import { IListener as Listener } from "#@/interfaces/listener";

export class RPCClient extends RPCEndpoint<ClientStub>
{
    protected registerStub(stub: ClientStub): void {
        this.stubRegistry.register(stub);
    }
    protected unregisterStub(stub: ClientStub): void {
        this.stubRegistry.remove(stub.stubName);
    }
    private _stubRegistry: Registry<ClientStub> = new Registry<ClientStub>((item):string=>{
        return item.stubName;
    });
    protected get stubRegistry(): Registry<ClientStub> { return this._stubRegistry;}

    private _stubIdToListenerRegIDs: TwoWayOTMMap = new TwoWayOTMMap();
    private _listenerIdToServerId: TwoWayMap = new TwoWayMap();

    
    private _stubIdToBindingId: TwoWayMap = new TwoWayMap();

    private lastTrunk: ITrunk|null=null;
    private pendingInvocations: InvocationData[] = [];

    constructor(trunk: ITrunk) {
        super(trunk);
        let eventEmitListener = (eventData:EventData, eventEmitData:EventEmitData)=>{
            //let eed:EventEmitData = d.data as EventEmitData;
            this.eventEmitRcvd(eventEmitData);
        }
        this.events.on(Messages.EventEmit, eventEmitListener);
    }

    protected onTrunkBound(trunk: ITrunk): void {
        this.lastTrunk = trunk;
    }

    protected onConnected(trunk: ITrunk): void {
        this.lastTrunk = trunk;
        this.info("RPC client Trunk Connected");
        this.bindStubs();
        this.invokePending();
    }

    protected onReconnected(trunk: ITrunk, attempts: number): void {
        this.lastTrunk = trunk;
        this.info("RPC Trunk Reconnected");
        // console.log("reconnect!!");
        this.bindStubs();
        this.invokePending();
    }

    protected onDisconnected(trunk: ITrunk, reason: string): void {
        this.info("RPC Trunk Disconnected");
        this.unbindStubs();
    }

    private registerEvent(
        stub: ClientStub, eventName: string, listener: Listener,
        once: boolean = false) {
        let reg: ListenerRegistration = {
            id: '',
            once: once,
            eventName: eventName,
            listener: listener,
            bindingState: BindingState.unbound
        };
        this.listenerRegistry.register(reg);
        this._stubIdToListenerRegIDs.add(stub.stubName, reg.id);
    }

    private unregisterEvent(registration: ListenerRegistration) {
        this.unbindEvent(registration);
        this._stubIdToListenerRegIDs.removeByB(registration.id);
        this.listenerRegistry.remove(registration.id);
    }

    public eventOn(
        stub: ClientStub, eventName: string, listener: Listener): this {
        this.registerEvent(stub, eventName, listener);
        this.bindEvents(stub);
        return this;
    }

    public eventOnce(
        stub: ClientStub, eventName: string, listener: Listener): this {
        this.registerEvent(stub, eventName, listener, true);
        this.bindEvents(stub);
        return this;
    }

    public eventRemoveListener(
        stub: ClientStub, eventName: string, listener: Listener): this {
        let removal: ListenerRegistration | null = null
        this._stubIdToListenerRegIDs.eachByA(stub.stubName, (regId) => {
            let reg = this.listenerRegistry.get(regId);
            if (
                (reg.eventName === eventName)
                && (listener === reg.listener)
                && (removal === null)) {
                removal = reg;
            }
        })
        if (removal !== null) {
            this.unregisterEvent(removal);
        }
        return this;
    }

    private unregisterEvents(stub: ClientStub) {
        let removals: ListenerRegistration[] = [];
        this._stubIdToListenerRegIDs.eachByA(stub.stubName, (regId) => {
            removals.push(this.listenerRegistry.get(regId));
        });
        for (let removal of removals) {
            this.unbindEvent(removal);
            this.unregisterEvent(removal);
        }
    }

    private enqueueInvocation(invocation: InvocationData) {
        this.pendingInvocations.push(invocation);
    }

    private dequeueInvocation():InvocationData {
        return this.pendingInvocations.shift() as InvocationData;
    }

    public requestInvoke(invocation: InvocationData) {
        //this.pendingInvocations.push(invocation);
        this.enqueueInvocation(invocation);
        this.invokePending();
    }

    private performInvocation(bindingId: string, invocation: InvocationData) {
        let imd:InvocationMessageData = {
            bindingId:bindingId,
            methodName: invocation.methodName,
            arguments: invocation.arguments
        }
        //let args: any[] = [Message.Invoke, bindingId, invocation.methodName];
        //args = args.concat(invocation.arguments);
        /*let args:[eventname:string, ...data:any[]] = [Messages.Invoke, imd];
        args.push((callbackData:CallbackData) => {
            if (callbackData.errMsg) {
                return invocation.reject(callbackData.errMsg);
            }
            invocation.resolve(callbackData.data);
        });
        if (this.lastTrunk) {
            let socket = this.lastTrunk.socket;
            if (socket) socket.emit.apply(socket, args);
        }*/
        if (this.lastTrunk) this.lastTrunk.transmit(Messages.Invoke,imd,(callbackData:CallbackData) => {
            if (callbackData.errMsg) {
                return invocation.reject(callbackData.errMsg);
            }
            invocation.resolve(callbackData.data);
        })
    }

    private invokePending() {
        if (!this.connected) return;
        let requeue: InvocationData[] = [];
        while (this.pendingInvocations.length > 0) {
            let invocation = this.dequeueInvocation();
            let bindingId = this._stubIdToBindingId.getByA(invocation.stub.stubName);
            if (bindingId !== undefined) {
                this.performInvocation(bindingId, invocation);
            }
            else {
                requeue.push(invocation);
            }
        }
        this.pendingInvocations = requeue;
    }

    private eventEmitRcvd = (eventEmitData:EventEmitData/*eventId: string, args: any[]*/) => {
        let listenerId = this._listenerIdToServerId.getByB(eventEmitData.eventBindingId);
        let t = this.listenerRegistry.get(listenerId);
        if (!t) return;
        if (t.once) {
            this.listenerRegistry.remove(t.id);
            this._listenerIdToServerId.removeByA(t.id);
            this._stubIdToListenerRegIDs.removeByB(t.id);
            this.unbindEvent(t);
        }
        t.listener.apply(null, eventEmitData.arguments);
    }

    /**
     * Bind the given event from it's server counterpart
     * @param bindingId Id for target listener stub
     * @param registration Registration details for the event to bind
     */
    private bindEvent(bindingId: string, listener: ListenerRegistration) {
        if (listener.bindingState !== BindingState.unbound) return;
        let serverId: string = '';
        if (this._listenerIdToServerId.hasA(listener.id)) {
            serverId = this._listenerIdToServerId.getByA(listener.id);
        }
        if (!this.lastTrunk) return;
        listener.bindingState=BindingState.binding;
        let ebd: EventBindingData = {
            bindingId:bindingId,
            command:"bind",
            header: {
                eventName: listener.eventName,
                once: listener.once,
                id: serverId
            }
        };
        if (this.lastTrunk) this.lastTrunk.transmit(
            Messages.EventBinding,
            ebd,
            (cd:CallbackData) => {
                this._listenerIdToServerId.add(listener.id, cd.data);
                listener.bindingState = BindingState.bound;
            }
        )
    }

    /**
     * Unbind the given event from it's server counterpart
     * @param registration Registration details for the event to unbind
     */
    private unbindEvent(registration: ListenerRegistration) {
        let serverId: string = '';
        if (this._listenerIdToServerId.hasA(registration.id)) {
            serverId = this._listenerIdToServerId.getByA(registration.id);
            this._listenerIdToServerId.removeByB(serverId);
        }
        if (registration.bindingState !== BindingState.bound) return;
        if (serverId === '') return;
        let ebd: EventBindingData = {
            bindingId: "",
            command: "remove",
            header: {
                eventName: registration.eventName,
                once: false,
                id: serverId
            }
        };
        if (!this.connected) return;
        if (this.lastTrunk) this.lastTrunk.transmit(
            Messages.EventBinding,
            ebd,
            (cd:CallbackData) => {
                registration.bindingState = BindingState.unbound;
            }
        )
    }

    /**
     * Bind events for the given stub to a listener on the server side
     * @param stub The stub to bind events for.
     */
    private bindEvents(stub: ClientStub) {
        // this.debug(stub);
        const bindingId = this._stubIdToBindingId.getByA(stub.stubName);
        if (!bindingId) return;
        this._stubIdToListenerRegIDs.eachByA(stub.stubName, (listenerId) => {
            this.bindEvent(bindingId, this.listenerRegistry.get(listenerId));
        });
    }

    /**
     * Unbind events for the given stub from server side listeners
     * @param stub The stub to unbind events for.
     */
    private unbindEvents(stub: ClientStub) {
        const bindingId = this._stubIdToBindingId.getByA(stub.stubName);
        this._stubIdToListenerRegIDs.eachByA(stub.stubName, (listenerId) => {
            this.unbindEvent(this.listenerRegistry.get(listenerId));
        });
    }

    /**
     * Bind stub to RPC server and register it's binding Id
     * @param stub The stub to bind
     */
    private bindStub(stub: ClientStub) {
        if (stub.bindingState !== BindingState.unbound) return;
        if (!this.connected) return;
        stub.setBindingState(BindingState.binding);
        let bindingData: BindingData = { stubName: stub.stubName };
        if (this.lastTrunk) this.lastTrunk.transmit(
            Messages.Bind,
            bindingData,
            (callbackData:CallbackData) => {
                if (callbackData.errMsg)
                {
                    return this.terminateStub(stub, callbackData.errMsg);
                }
                this._stubIdToBindingId.add(stub.stubName, callbackData.data);
                stub.setBindingState(BindingState.bound);
                this.bindEvents(stub);
                this.invokePending();
            }
        )
    }

    /**
     * Unbind stub from RPC server
     * @param stub The stub to bind
     */
    private unbindStub(stub: ClientStub) {
        this.unbindEvents(stub);
        let bindingId = this._stubIdToBindingId.getByA(stub.stubName);
        if (!bindingId) return;
        this._stubIdToBindingId.removeByB(bindingId);
        stub.setBindingState(BindingState.unbound);
        if (!this.connected) return;
        if (this.lastTrunk) this.lastTrunk.transmit(
            Messages.Unbind,
            bindingId
        )
    }

    /**
     * Run through the registerd stubs and bind each of them
     */
    private bindStubs() {
        if (!this.connected) return;
        this.stubRegistry.each((stub) => {
            this.bindStub(stub);
        })
    }

    /**
     * Run through the registerd stubs and unbind each of them
     */
    private unbindStubs() {
        this.stubRegistry.each((stub) => {
            this.unbindStub(stub);
        })
    }

    /**
     * Create an RPC stub and bind it to the RPC servers equivalent stub
     * @param constructor Constructor function for a stub extending ClientStub
     * @param altStubName Optional ID string to specify which server stub to use
     * @return Client stub - ready to use
     */
    public createStub<StubType extends ClientStub>(
        stubFactoryFunction: () => StubType, altStubName?: string): StubType {
        const newStub = super.createStub<StubType>(stubFactoryFunction);
        if (altStubName) {
            newStub.stubName = altStubName;
        }
        this.bindStubs();
        return newStub;
    }

    public async pendingIdToStub<T extends ClientStub>(
        pendingId:Promise<string>,
        stubFactoryFunction: () => T):Promise<T> {
        return this.createStub(stubFactoryFunction,await pendingId);
        /*return new Promise<T>((resolve, reject) => {
            promise.then((id)=>{
                resolve(this.createStub(constructor,id))
            })
            .catch((reason)=>{reject(reason);})
        });*/
    }

    public disposeStub(stub: ClientStub) {
        this.unbindStub(stub);
        this.unregisterEvents(stub);
        super.disposeStub(stub);
    }

    private terminateStub(stub: ClientStub, reason: string) {
        this.disposeStub(stub);
        //stub.emit("Terminated", reason);
        stub.terminate(reason);
    }

    public get connected(): boolean {
        return (!(!this.lastTrunk) && this.lastTrunk.connected);
    }
}
