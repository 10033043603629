import { CustomId } from '#@/utils/customId';
import { TokenRequestData } from '#@client/authentication/tokenRequestData'



export class Credentials {
    
    //#region Constructors

    constructor(initial?:Partial<Credentials>|null) {
        if (initial) Object.assign(this,initial);
    }

    //#endregion Constructors

    //#region Properties

    public get hasToken(): boolean {
        //console.log(this.tokenId)
        return (this.tokenId!==undefined);
    }
    
    public password:string|undefined=undefined;
    public tokenId:string|undefined=undefined;
    public tokenSecret:string|undefined=undefined;
    public username:string|undefined=undefined;

    //#endregion Properties

    //#region Methods

    public static clone(source:Credentials):Credentials
        {
            return new Credentials
            ({
                password:source.password,
                tokenId:source.tokenId,
                tokenSecret:source.tokenSecret,
                username:source.username
            });
        }

    public async generateTokenRequestData():Promise<TokenRequestData> {
        const tag = new CustomId();
        let rd = new TokenRequestData();
        rd.tokenId = this.tokenId ?? '';
        rd.tag = tag.asBased64();
        rd.signature = (await Credentials.signAwithB(tag, new CustomId(this.tokenSecret))).asBased64();
        return rd;
    }

    public ToNameValueCollection():object {
        return {
            username:this.username,
            password:this.password
        }
    }

    private static async signAwithB(message:CustomId,secret:CustomId):Promise<CustomId> {
        const mb = message.asUint8Array();
        const kb = secret.asUint8Array();
        let sb:Uint8Array = new Uint8Array(mb.byteLength+kb.byteLength);
        sb.set(mb,0);
        sb.set(kb,mb.byteLength);
        const ob:Uint8Array=new Uint8Array(await crypto.subtle.digest("SHA-512",sb));
        const output = new CustomId(ob);
        //console.log(output.buffer);
        return output;
    }

    //#endregion Methods
}