if (typeof btoa === 'undefined') {
    global.btoa = function (str) {
      return Buffer.from(str, 'binary').toString('base64');
    };
  }
  
  if (typeof atob === 'undefined') {
    global.atob = function (b64Encoded) {
      return Buffer.from(b64Encoded, 'base64').toString('binary');
    };
  }

/*export function arrayBufferFromBase64(data:string):ArrayBuffer {
    const raw = atob(data);
    const rawLenght = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLenght))
    for (let i=0;i<rawLenght;i++) {
        array[i]=raw.charCodeAt(i);
    }
    return array.buffer;
}*/

export class ArrayBufferConversions {
    static toString(buffer:ArrayBuffer,encoding:"base64"|"hex"):string {
        const encodings = {
            base64: (buffer:ArrayBuffer)=> btoa(String.fromCharCode.apply(null, Array.prototype.slice.apply(new Uint8Array(buffer)))),
            hex: (buffer:ArrayBuffer)=>[...new Uint8Array(buffer)].map(x => x.toString(16).padStart(2,'0')).join(''),
            default:(buffer:ArrayBuffer)=>""+buffer
        };
        return (encodings[encoding]??encodings.default)(buffer);
    }

    static parse(data:string,encoding:"base64"):ArrayBuffer {
        const encodings = {
            base64: (data:string)=> {
                const raw = atob(data);
                const rawLenght = raw.length;
                const array = new Uint8Array(new ArrayBuffer(rawLenght))
                for (let i=0;i<rawLenght;i++) {
                    array[i]=raw.charCodeAt(i);
                }
                return array.buffer;
            },
            default:(data:string)=>{throw new Error(`Parsing data from encoding (${encoding}) not implemented`);}
        };
        return (encodings[encoding]??encodings.default)(data);
    }
}