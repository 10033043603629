import { JobStatusEnum } from "#@client/taskRunner/job/jobStatusEnum";
import { BaseJob } from "#@client/taskRunner/job/baseJob";
import { ManagedJob as BaseManagedJob} from '#@/taskRunner/managedJob'
import { JobData } from "#@/taskRunner/data/jobData";
import { Id } from "#@/collections/typeId";


export class ManagedJob extends BaseManagedJob {
    //public id;
    //public taskName: string;

    public status:JobStatusEnum;

    private _job:BaseJob;
    public get job():BaseJob { return this._job; } 

    public get jobData():JobData { return this.job.asJobData(); }

    public get id():Id { return this.job.id; }
    public set id(value:Id) { this.job.setId(value!); }

    public get taskName():string|null { return this.job.taskName; }

    constructor(baseJob:BaseJob) {
        super();
        this._job = baseJob;
        this.status = JobStatusEnum.new;
    }
}