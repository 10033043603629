import { logOutputStdIO } from '#@/logging/logOutputStdIO'
import { LogOutput } /* as i_*/ from '#@/logging/interfaces'
import { LogEntry } from '#@/logging/logEntry'

/**
 * This class manages the routing of LogEntries to multiple outputs, defaulting to stdio.
 * It also manages a history of log entries and ensure entries have a unique id.
 */
export class LogOutputs {
    private _outputID: number = 0;
    private _logOutputs: LogOutput[] = []
    private _history:LogEntry[] = [];

    /**
     * If set to true, always output to stdio.
     * Otherwise stdio output occurs if no other outputs have been added.
     */
    public forceConsole:boolean = false;

    /**
     * Log the given entry and push it to registered outputs
     * @param logEntry the entry to log, and output
     */
    log(logEntry: LogEntry) {
        logEntry.outputId = (this._outputID++).toString();
        this._history.push(logEntry);
        if (this._history.length>50) this._history.shift();
        if (((this._logOutputs.length===0) && (process.stdout))||this.forceConsole) logOutputStdIO.log(logEntry)
        let i: number = 0;
        while (i < this._logOutputs.length) {
            this._logOutputs[i++].log(logEntry)
        }
    }

    /**
     * Returns an array of hostoric log entries.
     */
    public getHistory() {
        return this._history;
    }

    register(output: LogOutput) {
        if (this._logOutputs.indexOf(output) === -1) {
            this._logOutputs.push(output)
        }
    }

    unregister(output: LogOutput) {
        if (this._logOutputs.indexOf(output) > -1) {
            this._logOutputs.splice(this._logOutputs.indexOf(output), 1)
        }
    }
}

let logOutputs: LogOutputs = new LogOutputs();
export { logOutputs }