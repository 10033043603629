import { Config } from '#@client/configuration/config';
import { Context } from '#@client/core/context';
import { UrlUtils } from '#@client/utils/urlUtils';
import { Fetch } from '#@client/utils/fetch';
import { LogEmitter } from '#@/logging/logEmitter';
import { Credentials } from '#@client/authentication/credentials';

export class ConfigLoader {

    //#region Fields

    private static defaultConfig:Config = new Config({
        clientName: "{machineName}",
        dbgTag: "DBG",
        producerName: "Producer{.dbg}{.clientName}",
        taskNaming: "{taskAlias}{.dbg}{?.clientName}"
    });

    //#endregion Fields

    //#region Methods

    public static async load(source?:URL|string):Promise<Config> {
        const dataTypes:{[tn:string]:()=>Promise<Config>} = {
            undefined:async ()=>this.loadDefault(),
            string:async ()=>this.loadLocation(source as string),
            object:async ()=>{
                if (source instanceof URL) return this.loadURL(source as URL);
                return this.loadBase(new Config(source as Partial<Config>));
            },
            default:async ()=>{
                return new Config();
            }
        }
        return this.mergeDefaults(await (dataTypes[typeof source]??dataTypes.default)());
    }
    
    private static async loadBase(config:Config):Promise<Config> {
        if (config.baseUrl!==null) {
            const url = UrlUtils.absUrl(config.baseUrl);
            const baseConfig = await this.loadURL(url);
            return this.mergeConfig(baseConfig,config);
        }
        return config;
    }

    private static async loadDefault():Promise<Config> {
        return this.loadLocation(Context.GetDefaultConfigLocation())
    }

    private static async loadLocation(location:string):Promise<Config> {
        return this.loadURL(UrlUtils.absUrl(location));
    }

    private static async loadURL(url:URL,traverseCount:number=0):Promise<Config> {
        let cfgData:Partial<Config>|null=null;
        cfgData = await Fetch.GetObject<Partial<Config>>(url);
        if (cfgData===null) {
            if (traverseCount == 0) LogEmitter.general.warn(`Config not found at ${url.href}`);
            let upone:URL = UrlUtils.traverseUp(url);
            if (upone.href!==url.href) return this.loadURL(upone,traverseCount+1);
            return new Config();
        }
        if (traverseCount > 0) LogEmitter.general.warn(`Config found at ${url.href}`);
        let cfg=new Config(cfgData);
        if (cfg.baseUrl!==null) cfg.baseUrl = new URL(cfg.baseUrl,url).href
        if (cfg.inCloudUrl!==null) cfg.inCloudUrl = new URL(cfg.inCloudUrl,url).href
        //if (cfg.credentials) cfg.credentials = new Credentials(cfg.credentials);
        return await this.loadBase(cfg);
    }

    private static mergeConfig(a:Config, b:Config):Config {
        return new Config(
            {
                aliases:this.mergeDictionaries(a.aliases, b.aliases),
                apiRoutes:this.mergeDictionaries(a.apiRoutes, b.apiRoutes),
                baseUrl:a.baseUrl,
                clientName:b.clientName ?? a.clientName,
                credentials:this.mergeCredentials(a.credentials, b.credentials),
                dbgTag:b.dbgTag ?? a.dbgTag,
                debug:b.debug ?? a.debug,
                overrideDebugDetection:b.overrideDebugDetection ?? a.overrideDebugDetection,
                inCloudUrl:b.inCloudUrl ?? a.inCloudUrl,
                producerName:b.producerName ?? a.producerName,
                taskNaming:b.taskNaming ?? a.taskNaming,
            });
    }
    static mergeCredentials(a:Credentials | null, b:Credentials | null): Credentials | null {
        if (a === null && b === null) return null;
        if (a === null && b !== null) return Credentials.clone(b);
        if (a !== null && b === null) return Credentials.clone(a);
        if (a === null || b === null) return null;
        return new Credentials
        ({
            password : b.password ?? a.password,
            tokenId : b.tokenId ?? a.tokenId,
            tokenSecret : b.tokenSecret ?? a.tokenSecret,
            username : b.username ?? a.username,
        });
    }

    private static mergeDefaults(config: Config): Config {
        return this.mergeConfig(this.defaultConfig,config);
    }

    private static mergeDictionaries(a:{[key: string]: string},b:{[key: string]: string}):{[key: string]: string} {
        let merged:{[key: string]: string} = {};
        for (let k in a) {
            merged[k]=a[k];
        }
        for (let k in b) {
            merged[k]=b[k];
        }
        return merged;
    }

    
}