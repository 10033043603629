// import { Context } from "../../context";
//import { IJobReqResData } from "#@/taskRunner/data/jobReqRes";
import { BaseData } from "#@client/taskRunner/job/data/baseData";
import { Consumer } from "#@client/taskRunner/endpoints/consumer";
import { Producer } from "#@client/taskRunner/endpoints/producer";
import { JobData } from "#@/taskRunner/data/jobData";
import { SingleJobFactory } from "#@client/taskRunner/job/singleJobFactory";
import { JobStatusData } from "#@/taskRunner/data/jobStatus";
import { LogEmitter } from "#@/logging/logEmitter";
import { Id } from "#@/collections/typeId";
// import { TaskProvider } from "../task-provider";
// import { TaskClient } from "../task-client";
// import { IJobData, IJobReqResData } from "../../imports";

export class BaseJob extends LogEmitter {

    //#region Fields

    protected _rawData:JobData = new JobData();
    private _cancelled:boolean = false;
    private _consumer:Consumer|null = null;
    private _producer:Producer|null = null;

    //#endregion Fields

    //#region Constructors

    protected constructor() {
        super();
    }

    //#endregion Constructors

    //#region Properties

    public get consumer():Consumer|null { return this._consumer; }
    public set consumer(value:Consumer|null) { this._consumer = value; }
    public get defaultTaskName():string { return this.getDefaultTaskName(); }
    public get id():Id { return this._rawData.id; }
    public get producer():Producer|null { return this._producer!; }
    public set producer(value:Producer|null) { this._producer = value; }
    public get taskName():string|null { return this._rawData.taskName??null; }
    public set taskName(value:string|null) { this._rawData.taskName=value??undefined }

    //#endregion Properties

    //#region Methods

    public static fromJobData(jobData:JobData):BaseJob {
        let newJob:BaseJob = new BaseJob();
        newJob._rawData = jobData;
        newJob.onRawDataUpdated();
        return newJob;
    }

    public asJobData(): JobData {
        this.onUpdateRawData();
        return this._rawData;
    }
    
    public asJobStatusData(progress: number | null, message: string | null, updateResponse: boolean | null):JobStatusData
    {
        if (updateResponse) this.onUpdateRawData();
        return new JobStatusData({
            id:this.id,
            progress:progress??undefined,
            message:message??undefined,
            responseData:(updateResponse)?this._rawData.responseData:undefined
        })
    }

    public cancel():void;
    public cancel(reason:string|undefined):void
    public cancel(reason:string|undefined=""):void {
        if (typeof(reason)!="string") return this.cancel("");
        if (this.consumer!==null) { this.consumer.cancelJob(this,reason); return;}
        if (this.producer!==null) { this.producer.cancelJob(this,reason); return;}
    }

    public cancelReceived(caller:Producer):void;
    public cancelReceived(caller:Consumer):void;
    public cancelReceived(reason:string|undefined,caller:Producer):void;
    public cancelReceived(reason:string|undefined,caller:Consumer):void;
    public cancelReceived(reason:string|Producer|Consumer|undefined="", caller?:Producer|Consumer):void {
        if (reason instanceof Producer) return this.cancelReceived("",reason);
        if (reason instanceof Consumer) return this.cancelReceived("",reason);
        //if (typeof(reason)!="string") return this.cancelReceived("");
        if (caller instanceof Producer || caller instanceof Consumer) { this.emitCancelled(reason); return; }
        if (this.consumer!==null) { this.consumer.cancelJob(this,reason); return;}
        if (this.producer!==null) { this.producer.cancelJob(this,reason); return;}
    }
    

    public castTo<T extends BaseJob>(factory:SingleJobFactory<T>): T {
        let newT:T = factory.createJob();
        newT._rawData = this._rawData;
        newT.onRawDataUpdated();
        newT.consumer = this.consumer;
        newT.producer = this.producer;
        return newT;
    }

    public emitFinished():void {
        //this.emit("finished",this);
        this.onEmitFinished();
    }

    public finish():void {
        if (this.producer!==null) {
            this.producer.sendFinished(this);
        }
    }

    public initialise():void { }

    public loadRawResponseData(responseData: object) {
        this._rawData.responseData = responseData;
        this.onRawDataUpdated();
    }

    public setId(newId:string):void
    {
        this._rawData.id = newId;
    }
    
    public start() {
        if (this.consumer!==null) {
            this.consumer.requestJob(this);
        }
    }

    public updateStatus(progress:number):void;
    public updateStatus(message:string):void;
    public updateStatus(updateResponse:boolean):void;
    public updateStatus(progress:number,message:string):void;
    public updateStatus(progress:number|null, updateResponse:boolean|null):void;
    public updateStatus(message:string|null, updateResponse:boolean|null):void;
    public updateStatus(progress:number|string|boolean|null, message:string|boolean|null, updateResponse:boolean|null):void;
    public updateStatus(progress:number|string|boolean|null = null, message:string|boolean|null = null, updateResponse:boolean|null = null):void {
        if (typeof(message) == "boolean") return this.updateStatus(progress,null,message);
        if (typeof(progress)== "string") return this.updateStatus(null,progress,updateResponse);
        if (typeof(progress)=="boolean") return this.updateStatus(null,null,progress);
        if (this.consumer!==null) {
            this.emitStatus(progress, message, updateResponse);
        }
        if (this.producer!==null) {
            this.producer.sendStatus(progress, message, updateResponse, this);
        }
    }

    protected getDefaultTaskName():string {
        return "BaseJob";
    }

    protected onEmitCancelled(reason:string): void
    {
        this.emit("cancelled", reason, this);
    }

    protected onEmitFinished(): void
    {
        this.emit("finished", this);
    }

    protected onEmitProgressChanged(value:number): void
    {
        this.emit("progressChanged", value, this);
    }

    protected onEmitResultsChanged(): void
    {
        this.emit("resultsChanged", this);
    }

    protected onEmitStatusMessageChanged(message:string): void
    {
        this.emit("statusMessageChanged", message, this);
    }

    protected onRawDataUpdated():void {}

    protected onUpdateRawData():void {}

    private emitCancelled(reason:string):void {
        this._cancelled = true;
        //this.emit("cancelled", reason, this);
        this.onEmitCancelled(reason);
    }

    protected emitStatus(progress:number|null, message:string|null, updateResponse:boolean|null):void {
        if (progress!==null) this.onEmitProgressChanged(progress);
        if (message!==null) this.onEmitStatusMessageChanged(message);
        if ((updateResponse!==null) && (updateResponse===true)) this.onEmitResultsChanged();
    }

    //#endregion Methods

    /*public get cancelled():boolean { return this._cancelled; }

    protected _jRequestData:object = new BaseData();
    protected _jResponseData:object = new BaseData();

    public get jRequestData():object {
        return this._jRequestData;
    }
    public set jRequestData(data:object) {
        this._jRequestData = data;
    }
    public get jResponseData():object {
        return this._jResponseData;
    }
    public set jResponseData(data:object) {
        this._jResponseData = data;
    }*/

    /*public static parseJob(job:BaseJob|any):BaseJob {
        if (job instanceof BaseJob) {
            return job;
        } else {}
        let rT:BaseJob = new BaseJob();
        //if (job.clientId) rT.pClientId = job.clientId;
        //if (job.brokerId) rT.pBrokerId = job.brokerId;
        //if (job.providerId) rT.pProviderId = job.providerId;
        if (job.id) rT.pId = job.id;
        //if (job.taskName) rT.pTaskName = job.taskName;
        if (job.requestData) rT.jRequestData = job.requestData;
        if (job.responseData) rT.jResponseData = job.responseData;
            return rT;
    }*/

    

    
    
}
