import { Stub } from "#@/rpcSystem/stubs/stub";
import { RPCClient} from "#@/rpcSystem/endpoints/client";
import { IInvocationData as InvocationData } from '#@/rpcSystem/events/interfaces/invocationData';
import { BindingState } from '#@/rpcSystem/enums/bindingState';

export abstract class ClientStub extends Stub/*<ClientStub>*/ {

    private bindableEvents:string[] = [];
    //public remoteId:string='';
    //public client: RPCClient;
    private _bindingState:BindingState = BindingState.unbound;
    public get bindingState():BindingState { return this._bindingState;}
    get client():RPCClient {
        return this.endpoint as RPCClient;
    }

    /*constructor(public client:RPCClient,remoteId:string) {
        super();
        this.client=client;
        this.endpoint=client;
        this.remoteId=remoteId;
    }*/

    constructor() {
        super();
        this.bindableEvents = this.getBindableEventNames();
    }
    protected abstract getBindableEventNames(): string[];

    private eventBindable(event:string) {
        return this.bindableEvents.indexOf(event)!==-1;
    }

    public on(event:string,listener:(...args:any[])=>void):this {
        if (this.eventBindable(event))
        {
            this.client.eventOn(this,event,listener);
            return this;
        }
        return super.on(event,listener);
    }

    public once(event:string,listener:(...args:any[])=>void):this {
        if (this.eventBindable(event))
        {
            this.client.eventOnce(this,event,listener);
            return this;
        }
        return super.once(event,listener);
    }

    public removeListener(event:string,listener:(...args:any[])=>void):this {
        if (this.eventBindable(event))
        {
            this.client.eventRemoveListener(this,event,listener);
            return this;
        }
        return super.removeListener(event,listener);
    }

    public invoke<T>(methodName:string, ...data:any[]):Promise<T> {
        return new Promise<T>((res,rej)=>{
            const resolve = (data:T):void => { res(data); }
            const reject = (reason:string):void => {rej.call(this,reason); console.log("invoke rejected"); }
            const invocation: InvocationData = {
                methodName: methodName,
                arguments: data,
                resolve:res,// resolve,// (hack:any):void=>{res(hack);},
                reject:rej,// ect,// (hack:string):void=>{console.log("invoke rejected");rej(hack);},
                stub:this
                // promise: promise
            }
            this.client.requestInvoke(invocation);
        });
    }

    /*public onceBound<T extends ClientStub>():Promise<T> {
        return new Promise<T>((resolve, reject)=>{
            if (this._bindingState===BindingState.bound) {
                return resolve(this as unknown as T);
            }
            let checkBinding = (newState:BindingState) => {
                if (newState===BindingState.bound) {
                    this.removeListener("bindingState",checkBinding);
                    resolve(this as unknown as T);
                }
                if (newState===BindingState.closed) {
                    this.removeListener("bindingState",checkBinding);
                }
            }
            this.on("bindingState",checkBinding);
            this.once("terminated",(reason:string)=>{
                this.removeListener("bindingState",checkBinding);
                reject(reason);
            })
        });
    }*/

    public onceBound(next:()=>void):void {
        if (this._bindingState===BindingState.bound) { next(); return; }
        let checkBinding = (newState:BindingState) => {
            if (newState===BindingState.bound) {
                this.removeListener("bindingState",checkBinding);
                //resolve(this as unknown as T);
                next();
            }
            if (newState===BindingState.closed) {
                this.removeListener("bindingState",checkBinding);
            }
        }
        this.on("bindingState",checkBinding);
        this.once("terminated",(reason:string)=>{
            this.removeListener("bindingState",checkBinding);
            //reject(reason);
        })
    }

    public setBindingState(newState:BindingState) {
        // console.log(`Set bstate ${this.id} ${newState}`);
        this._bindingState = newState;
        this.emit("bindingState", newState);
    }

    
}
