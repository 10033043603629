//import { LogOutput, LogEntry, LogEmitter, logOutputs, LoggingInterfaces } from ".";
import { LogOutput } from '#@/logging/interfaces';
//import { logOutputs } from '#@/logging/logOutputs';
import { LogEntry } from '#@/logging/logEntry';
import { LogEmitter } from '#@/logging/logEmitter';
import { StackEntry } from '#@/logging/interfaces';
import {format as dateFormat} from 'date-and-time';

interface i_logOutput {
    fn: (...args:any[])=>any;
    label: string;
    labelStyle: string;
    symbol: string;
}

export class LogOutputBrowser implements LogOutput {

    private loglevels: i_logOutput[] = [
        { fn: console.log, label: "NONE", labelStyle: "",  symbol: " " },
        { fn: console.error, label: "FATAL",  labelStyle: "color:red; font-weight:bolder;", symbol: "\u{2620}\u{FE0F}" }, //Bright red
        { fn: console.error, label: "ERROR", labelStyle: "color:red;",  symbol: "\u{1F6A8}" }, //Red
        { fn: console.warn, label: "WARN ", labelStyle: "color:orange;",  symbol: "\u{26A0}\u{FE0F}" }, //Yellow
        { fn: console.info, label: "INFO ", labelStyle: "color:green;",  symbol: "\u{2139}\u{FE0F}" }, //green
        { fn: console.log, label: "DEBUG", labelStyle: "color:blue;",  symbol: "\u{1F41C}" }, //blue
        { fn: console.log, label: "TRACE", labelStyle: "color:purple;",  symbol: "\u{1F52C}" } //purple
    ];

    logEmitterRoute(logEmitter: LogEmitter): void {

    }

    private _indent(what: string, wth: string): string {
        return what.replace(/^/gm, wth);
    }

    _traceFormat(trace: StackEntry[]): string {
        var os = "";
        for (var i = 0; i < trace.length; i += 1) {
            if (os !== "") os += "\n";
            os += "at " + trace[i].method + " (" + trace[i].module + ":" + trace[i].line + ":" + trace[i].column + ")";
        }
        return os;
    }

    public maxLevel:number = 7

    log(logEntry: LogEntry) {

        //console.log('dlo', logEntry.body)
        //if (level<=self.level) {
        if (logEntry.level>this.maxLevel) return;
        var ll = this.loglevels[logEntry.level];
        //var m = moment(logEntry.time);
        var largs:any[] = [`${ll.symbol} [${dateFormat(logEntry.time, "HH:mm:ss.SSS")}] %c${ll.label} : %c${logEntry.subject}`,ll.labelStyle,""];
        if (logEntry.route) {
            largs[0]+=`\n   %c${logEntry.route.join(" > ")}`
            largs.push("color: darkcyan;font-size:smaller;");
        }
        if (logEntry.body) {
            largs[0]+=`\n%c${this._indent(logEntry.body, "      ")}`;
            largs.push("");
        }
        if (logEntry.data) {
            largs[0]+=`\n%c   Data...%o`;
            largs.push("");
            largs.push(logEntry.data);
            //if (logEntry.data instanceof Array) {
            //            process.stdout.write('   Data...\n' + this._indent(nodeUtil.format.apply(nodeUtil, [[logEntry.data]]), "      ") + '\n');
            //} else {
            //  process.stdout.write('   Data...\n'+this.indent(util.format.apply(util,logEntry.data),"      ") + '\n');
            //}
        }
        if (logEntry.stackTrace) {
            largs[0]+='\n   Call Stack:\n' + this._indent(this._traceFormat(logEntry.stackTrace), "      ");
        }
        //console.log(largs);
        ll.fn.apply(console,largs);
        /*var ld = ll.symbol + " [" + m.format("HH:mm:ss.SSS") + "] " + ll.label + ": \x1B[0m" + logEntry.subject
        ll.fn(ld + '\n');
        if (logEntry.route) process.stdout.write(`   \x1B[0;34m${logEntry.route.join(' > ')}\x1B[0m \n`);
        if (logEntry.body) process.stdout.write(this._indent(logEntry.body, '      ') + '\n');
        if (logEntry.data) {
            //if (logEntry.data instanceof Array) {
            //            process.stdout.write('   Data...\n' + this._indent(nodeUtil.format.apply(nodeUtil, [[logEntry.data]]), "      ") + '\n');
            //} else {
            //  process.stdout.write('   Data...\n'+this.indent(util.format.apply(util,logEntry.data),"      ") + '\n');
            //}
        }
        if (logEntry.stackTrace) process.stdout.write('   Call Stack:\n' + this._indent(this._traceFormat(logEntry.stackTrace), "      ") + '\n');
         */
        //console.log(arguments);
        //}
    }
}

//logOutputs.add(new LogOutputBrowser());
//console.log("dodod");
