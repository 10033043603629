import { LogEmitter } from "#@/logging/logEmitter";
import { Context } from "#@client/core/context";

import { EndpointStub } from '#@client/taskRunner/rpcStubs/endpoint';
import { Id } from '#@/collections/typeId';
import { Broker } from '#@client/taskRunner/rpcStubs/broker';
import { IEndpointRegistration } from "#@/taskRunner/data/endpointRegistration";
import { JobManager } from "#@client/taskRunner/job/jobManager";
//import { EndpointStub } from "../rpcStubs/endpoint";

export abstract class Endpoint extends LogEmitter {
    public id:Id = null;
    public name:String = "Endpoint";
    protected context:Context;
    private stub:EndpointStub|null=null;

    private _jobManager: JobManager = new JobManager();
    protected get manager():JobManager { return this._jobManager; }

    private _registered: boolean = false;
    public get registered():boolean { return this._registered; }

    private _previouslyRegistered: boolean = false;

    constructor(context:Context) {
        super();
        this.context = context;
    }

    public onceRegistered(next:()=>void):void {
        if (this.registered) { next(); return;}
        this.once("registered",()=>{next();});
    }

    private stubTerminated(stub:EndpointStub,reason:string) {
        if (stub.brokerStub===null) return this.error("Broker stub not set");
        let brokerStub = stub.brokerStub;
        this._registered = false;
        /*brokerStub.onceBound().then(()=>{
            this.recreateStub(brokerStub);
        })*/
        brokerStub.onceBound(()=>{
            this.recreateStub(brokerStub);
        })
    }

    public setStub(stub:EndpointStub) {
        this.stub= stub;
        let registerData:IEndpointRegistration = {
            id: this.id,
            name: this.name
        }
        stub.register(registerData).then((endpointId:Id)=>{
            this.id = endpointId;
            this._registered = true;
            this._previouslyRegistered = true;
            this.emit("registered");
            this.onRegistered();
        }).catch((e)=>{this.error(e);}); 
        stub.on("terminated",(reason:string)=>{ 
            this.stubTerminated(stub,reason);
        });
    }

    protected abstract recreateStub(brokerStub:Broker):void;
    protected onRegistered():void {}

    private _disposed:Boolean = false;
    public dispose() {
        this.onDisposed();
        if (this.stub===null) this.error("stub not set");
        else this.stub.dispose();
        this._disposed = true;
    }
    protected onDisposed():void {}
}
