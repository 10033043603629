import { Registry } from "#@/collections/registry";
import { Id } from "#@/collections/typeId";
import { LogEmitter } from "#@/logging/logEmitter";
import {ManagedJob} from '#@/taskRunner/managedJob';

type QueryFunc<T> = (t:T)=>boolean;
type ActionFunc<T> = (t:T)=>void;

export abstract class GenericJobManager<T extends ManagedJob> extends LogEmitter {

    //#region Fields

    private _jobQueue:T[] = [];
    private _jobsById:Registry<T> = new Registry<T>((i,newid)=>(newid)?i.id=newid:i.id);

    //#endregion Fields

    //#region Methods
    
    public addJob(managedJob:T) {
        this._jobsById.register(managedJob);
    }

    public deleteJob(jobId:Id):void {
        //iif (jobId instanceof CustomId) jobId = jobId.string;
        //this.dumpData();
        for (let i = this._jobQueue.length - 1; i >= 0; i--) {
            let managedJob = this._jobQueue[i];
            if (managedJob.id === jobId) this._jobQueue.splice(i,1);
        }
        //delete this._jobsById.get(id);
        this._jobsById.remove(jobId as string);
        //this.dumpData();
    }    

    public dequeueJob(action:ActionFunc<T>):void;
    public dequeueJob(taskName:string,action:ActionFunc<T>):void;
    public dequeueJob(taskNames:string[],action:ActionFunc<T>):void;
    public dequeueJob(testFunc:QueryFunc<T>,action:ActionFunc<T>):void;
    public dequeueJob(query:ActionFunc<T>|QueryFunc<T>|string|string[],action?:ActionFunc<T>):void {
        if (!action) { this.dequeueJob((t)=>true,query as ActionFunc<T>); return;}
        if (typeof action !== "function") return;
        if (typeof query == "string") { this.dequeueJob((t)=>t.taskName==query,action); return; }
        if (Array.isArray(query)) { this.dequeueJob((t)=>(query.length==0)||query.indexOf(t.taskName??'')>-1,action); return; }
        //this.warn(`Dequeue Job: There are ${this._jobQueue.length} jobs`);
        let i=-1;
        while (++i<this._jobQueue.length) {
            let managedJob:T = this._jobQueue[i];
            if (query(managedJob)) {
                this._jobQueue.splice(i,1);
                action(managedJob);
                return;
            }
        }
    }

    public enqueueJob(managedJob:T) {
        this.addJob(managedJob);
        this._jobQueue.push(managedJob);
        //this.warn(`Enqueue Job: There are ${this._jobQueue.length} jobs`);
        
        setImmediate(() => {
            this.emit("listChanged");
        });
    }

    public find(queryFunc:QueryFunc<T>, action:ActionFunc<T[]>) {
        let found:T[] = []
        //TODO: Implement find function
        /* for (let key in this._jobsById) {
            let managedJob:T = this._jobsById.get(id);
            if (queryFunc(managedJob)) {
                found.push(managedJob);
            }
            //os+=`${key}: ${this._jobsById[key].taskName}\n`;
        }*/
        action(found);
    }

    public getJob(id:Id):T|null {
        if ((id!=="") && (this._jobsById.get(id))) {
          return this._jobsById.get(id)
        }
        return null;
    }

    public hasJob(id:Id):boolean {
        return this._jobsById.contains(id);
    }

    private dumpData() {
        let os = "\nJob Queue\n";
            os+= "=========\n";
        for (let i=0;i<this._jobQueue.length;i++) {
            os+=`${this._jobQueue[i].id}: ${this._jobQueue[i].taskName}\n`;
        }
        os+="All Jobs\n";
        os+="========\n";
        /* for (let key in this._jobsById) {
            os+=`${key}: ${this._jobsById.get(id).taskName}\n`;
        }*/
        os+="\n";
        this.warn(os);
    }

    //#endregion Methods

}
