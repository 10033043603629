import { LogEmitter } from "#@/logging/logEmitter";
import { ITrunkBase } from "#@/interfaces/trunkBase";
import { ITrunk } from "#@/interfaces/trunk";
import { RebindableEvents } from "#@/eventSystem/rebindableEvents";

export class TrunkBinding extends LogEmitter {
    protected onReconnected(trunk: ITrunk, attempts: number) {
        throw new Error("Method not implemented.");
    }
    protected onDisconnected(trunk: ITrunk, reason: string) {
        throw new Error("Method not implemented.");
    }
    
    // private trunk: ITrunk;

    protected events:RebindableEvents = new RebindableEvents();

    constructor(trunk:ITrunkBase) {
        super();
        // this.trunk = trunk;
        trunk.on("connected", this._trunkConnected);
        trunk.on("reconnected", this._trunkReconnected)
        trunk.on("disconnected", this._trunkDisconnected);
        if (trunk.connected) {
            this._trunkConnected(trunk as ITrunk);
        }
    }

    protected onTrunkUnbinding(trunk:ITrunk) {
        if (trunk.socket) this.events.removeTarget(trunk.socket);
    }

    private unbindTrunk(trunk:ITrunk) {
        trunk.off("disconnected",this._trunkDisconnected);
        this.onTrunkUnbinding(trunk);
        //if (trunk.socket) this.events.removeTarget(trunk.socket);
        // trunk.removeListener("connected", this._trunkConnected);
        // trunk.removeListener("reconnected", this._trunkReconnected);
        // trunk.removeListener("disconnected", this._trunkDisconnected);
    }

    protected onTrunkBinding(trunk:ITrunk) {
        if (trunk.socket) this.events.addTarget(trunk.socket);
    }

    private bindTrunk(trunk:ITrunk) {
        // trunk.on("connected", this._trunkConnected);
        // trunk.on("reconnected", this._trunkReconnected)
        trunk.on("disconnected", this._trunkDisconnected);
        //if (trunk.socket) this.events.addTarget(trunk.socket);
        this.onTrunkBinding(trunk);
        this.onTrunkBound(trunk);
    }

    protected onTrunkBound(trunk:ITrunk) {

    }

    protected _trunkConnected = (trunk:ITrunk) => {
        // console.log("Trunk Connnnnnected");
        //this.trace("Trunk Connected",trunk.socket)
        this.unbindTrunk(trunk);
        this.bindTrunk(trunk);
        this.onConnected(trunk);
    }

    protected onConnected(trunk:ITrunk) {}

    protected _trunkReconnected = (trunk:ITrunk, attempts:number) => {
        // console.log("TRUNK RECONNECTION?")
        //this.unbindTrunk(trunk);
        this.bindTrunk(trunk);
        this.onReconnected(trunk, attempts);
    }

    protected _trunkDisconnected = (trunk:ITrunk, reason:string) => {
        // console.log("Trunk disconnection");
        this.unbindTrunk(trunk);
        this.onDisconnected(trunk, reason);
    }
}