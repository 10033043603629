/*import { IJobData } from "./interfaces/i-job-data";
import { CustomId } from "../../../common/util";
import { LogEmitter } from "../../../common/logging";
import { Id } from "../../../common/collections/type-id";*/

import { Id } from "#@/collections/typeId";
import { LogEmitter } from "#@/logging/logEmitter";
import { JobData } from '#@/taskRunner/data/jobData';

export abstract class ManagedJob extends LogEmitter {

    //private _job:IJobData;
    //public get job():IJobData { return this._job; } 
    public abstract get jobData():JobData;// { return this.job; }

    public abstract get id():Id; // { return new CustomId(); }
    public abstract set id(value:Id); // { return new CustomId(); }

    public abstract get taskName():string|null;// { return ""; }

    //constructor() {
        //this._job = jobData;
    //}
}
