import { Client } from "#@client/core/client";
import ky, { Options } from "ky";

export class Fetch {

    constructor(public icClient:Client) {

    }

    private standardOptions(other:any={}):any {
        let options:any = {
            mode:'cors'
        };
        if (this.icClient.auth.accessToken!="d") {
            options['headers']={
                "Authorization":`Bearer ${this.icClient.auth.accessToken}`
            }
        }
        return {...options,...other};
    }

    async postObject<PostType,ReturnType>(url: URL, data:PostType):Promise<ReturnType|null> {
        let response = await ky.post(url,/*{mode:'cors',credentials:'include',json:data}*/this.standardOptions({json:data})).json().catch((e)=>{});
        if (!response) return null;
        return response as ReturnType;
    }
    
    async getObject<T>(url: URL):Promise<T|null>
    {
        let response = await (ky(url,/*{mode:'cors',credentials:'include',headers:{
            "Authorization":`Bearer ${this.icClient.auth.accessToken}`
        }}*/this.standardOptions()).json()).catch((e)=>{});
        if (!response) return null;
        return response as T;
    }

    static async GetObject<T>(url: URL):Promise<T|null>
    {
        let response = await (ky(url,{mode:'cors',credentials:'include'}).json()).catch((e)=>{});
        if (!response) return null;
        return response as T;
    }

}