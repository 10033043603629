import { fetchOptions, IOptions } from './options';
import { RPCClient } from "#@/rpcSystem/endpoints/client";
import { ClientStub } from "#@/rpcSystem/stubs/clientStub";
//import sioClient, {Socket} from 'socket.io-client'
//import {Trunk as ctrunk} from '#@/socketIo/trunk';
import { Trunk } from '#@client/webSockets/trunk';
//import { LogOutput } from '#@/logging/interfaces';
import { logOutputs } from '#@/logging/logOutputs';
//import { LogEntry } from '#@/logging/logEntry';
import {LogOutputBrowser} from './logOutputBrowser';
import { PlatformClientStub } from "#@/rpcStubs/platformClientStub";
import { LogEmitter } from '#@/logging/logEmitter';
import { LogEntry } from '#@/logging/logEntry';
import { Authenticator } from '#@client/authentication/authenticator';
import ky from 'ky';
import { Consumer } from '#@client/taskRunner/endpoints/consumer';
import { Context } from '#@client/core/context';
import { Broker } from "#@client/taskRunner/rpcStubs/broker";
import { singlePromise } from '#@/utils/promises';
import { ConsumerStub } from '#@client/taskRunner/rpcStubs/consumer';
import { resetRetrieveHandlers } from 'source-map-support';
import { Config } from '#@client/configuration/config';
import { ConfigLoader } from '#@client/configuration/configLoader';
import { Fetch } from '#@client/utils/fetch';
import { GenericBaseJob } from '#@client/taskRunner/job/genericBaseJob';
import { SingleJobFactory } from '#@client/taskRunner/job/singleJobFactory';
import { BaseData } from '#@client/taskRunner/job/data/baseData';
import { Producer } from '#@client/taskRunner/endpoints/producer';
import { ProducerStub } from '#@client/taskRunner/rpcStubs/producer';

const TaskRunner = {GenericBaseJob, SingleJobFactory,BaseData};

export { TaskRunner };

//class LogConsole implements LogOutput {
    //log (entry: LogEntry) {
        //console.log(entry);
    //};

//}

logOutputs.register(new LogOutputBrowser());


const DEFAULTCFG='cpconfig.json';

/*function quck(emitter:Socket,name:string) {
    emitter.on(name,(...args:any[])=>{console.log(emitter,name,...args)});
}*/

export class CloudLogEmitter extends LogEmitter {

    emitEntry(logEntry: LogEntry) {
        logEntry = LogEntry.Clone(logEntry);//.clone();
        logEntry.route.splice(0, 0, ' \u26C5 Cloud');
        //this.emit('log', data);
        this._emitLog(logEntry)
    }

}


const cle = new CloudLogEmitter();

export class Client extends LogEmitter {

    private _rpcCore:RPCClient;
    public auth:Authenticator;
    public fetch: Fetch;
    private ioTrunk:Trunk;
    context:Context;// = new Context();

    private constructor(public config:Config) {
        super();
        //console.log("Core made with options",config);
        this.context = new Context(config);
        /*let a=sioClient();
        quck(a,'connect');
        //quck(a,'connect_error');
        a.on("connect_error",(err:any)=>{
            if (err) {
                console.log(err.message);
                console.log(err.data);
            }
        })
        quck(a,'disconnect');
        quck(a,'reconnect');
        quck(a,'error');
        quck(a,'message');
        a.onAny((n:string,...args:any[])=>{
            console.log(n,...args);
        })*/
        this.auth = new Authenticator(this);
        this.fetch = new Fetch(this);
        this.ioTrunk = new Trunk(this/*sioClient({autoConnect:false})*/);
        this._rpcCore = new RPCClient(this.ioTrunk);
        /*let stub = this._rpcCore.createStub(PlatformClientStub);
        this.handleStub(stub);*/
        //stub.echo("hello there").then((r)=>{
        //    console.log(r);
        //})
        //const rpcCore = new RPCClient(this.ioTrunk);
        //console.log("Eager")
        //rpcCore.createStub(()=>new PlatformClientStub()).echo("hello there").then((r)=>{console.log(r)})

        this.auth.on("signedIn",this.signInHandler)
        this.auth.on("signedOut",this.signOutHandler)
        this.auth.signIn().then((se)=>{
            console.log("Auto SignIn...",se);
        })
        //this.ioTrunk.open();
    }

    signInHandler = ()=>{
        this.info("Sign In");
        this.trace("Open Trunk");
        this.ioTrunk.open();
    }

    signOutHandler = ()=>{
        this.info("Sign Out");
        this.trace("Close Trunk");
        this.ioTrunk.close();
    }

    async handleStub(stub:PlatformClientStub) {
        //let r:string=await stub.echo("hello there");
        //console.log(r);
        let d = await stub.createLogOutputStub();
        /*for (const e of await d.getHistory()) {
            cle.emitEntry(e);
        }*/
    }

    goPing() {
        console.log("also ping");
    }

    private brokerStub:Broker|null = null;

    private async getBrokerStub():Promise<Broker> {
        if (this.brokerStub!==null) return this.brokerStub;
        this.brokerStub = this.createRPCStub(()=>new Broker);
        return this.brokerStub;
    }
    
    private async spawnConsumerStub():Promise<ConsumerStub> {
        return (await this.getBrokerStub()).createConsumer();
    }

    private async spawnProducerStub():Promise<ProducerStub> {
        return (await this.getBrokerStub()).createProducer();
    }

    private _consumer:Consumer|null=null;
    private async getConsumer(): Promise<Consumer> {
        if (this._consumer!==null) return this._consumer;
        const newConsumer = await this.createConsumer();
        this._consumer=newConsumer;
        return this._consumer;
    }

    public async createConsumer():Promise<Consumer> {
        this.warn("Create Consumer2")
        const newConsumer = new Consumer(this.context);
        this.warn("Create Consumer3")
        const newConsumerStub  = await this.spawnConsumerStub();
        this.warn("Got Stub")
        newConsumer.setStub(newConsumerStub);
        return newConsumer;
    }

    public async createProducer():Promise<Producer> {
        const newProducer = new Producer(this.context);
        const newProducerStub  = await this.spawnProducerStub();
        newProducer.setStub(newProducerStub);
        return newProducer;
    }

    /**
     * Create an RPC Stub of the required type
     * @param constructor *JS ONLY*
     * @returns A promise that will resolve with the new stub
     */
     public createRPCStub<T extends ClientStub>(
        stubFactoryFunction: () => T
        )//: Promise<T> 
    {
        return this._rpcCore.createStub(stubFactoryFunction);
    }

    static async create():Promise<Client> ;
    static async create(options:Config):Promise<Client>;
    static async create(url:string):Promise<Client>;
    static async create(url:URL):Promise<Client>;

    static async create(options?:Config|URL|string):Promise<Client> {
        if (options instanceof Config) return new Client(options);
        return new Client(await ConfigLoader.load(options));
    }
}
