import { LogEmitter } from "#@/logging/logEmitter";
import { BaseJob } from "#@client/taskRunner/job/baseJob";
import { BaseJobHandler } from "#@client/taskRunner/job/baseJobHandler";
//import { LogEmitter } from "../../../common/logging/log-emitter";
import { Producer } from "#@client/taskRunner/endpoints/producer";
import { SingleJobFactory } from "#@client/taskRunner/job/singleJobFactory";

// import { BaseJob } from "./job/base-job";
// import { TaskProvider } from "./task-provider";
// import { LogEmitter } from "../imports"


export class JobHandler<JobType extends BaseJob> extends BaseJobHandler {
    public canHandle(job: BaseJob): boolean {
        return (this.taskName === job.taskName);
    }

    private _taskName: string;
    private _factory: SingleJobFactory<JobType>;
    public get taskName():string { return this._taskName; }
    //private _jobClass: new()=>T;

    constructor(factory:SingleJobFactory<JobType>, taskName: string) {
        super();
        this._taskName=taskName;
        //this._jobClass = jobClass;
        this._factory = factory;
    }

    invokeConsiderJob(baseJob:BaseJob, producer:Producer):boolean {
        if (this.canHandle(baseJob)) {
            if (this.listenerCount("considerJob")>0) {
                this.onEmitConsiderJob(baseJob as JobType, producer);
                return true;
            }
        }
        return false;
    }

    invokeStartJob(baseJob:BaseJob, producer:Producer) {
        if (this.canHandle(baseJob)) {
            this.onEmitStartJob(baseJob as JobType, producer);
        }
    }

    invokeCancelJob(baseJob:BaseJob, reason:string, producer:Producer) {
        if (this.canHandle(baseJob)) {
            //baseJob.emitCancelled(reason);
            baseJob.cancelReceived(reason, producer);
            this.onEmitCancelJob(baseJob as JobType, reason);
        }
    }

    castUp(job:BaseJob):JobType {
        return job.castTo(this._factory);
    }

    protected onEmitCancelJob(job:JobType,reason:string):void
    {
        this.emit("cancelJob", job, reason);
    }

    protected onEmitConsiderJob(job:JobType,producer:Producer):void
    {
        this.emit("considerJob", job, producer);
    }

    protected onEmitStartJob(job:JobType,producer:Producer):void
    {
        this.emit("startJob", job, producer);
    }

}
