import { Id } from "#@/collections/typeId";
import { JobCancelData } from "#@/taskRunner/data/jobCancel";
import { JobData } from "#@/taskRunner/data/jobData";
import { JobStatusData } from "#@/taskRunner/data/jobStatus";
import { EndpointStub } from "#@client/taskRunner/rpcStubs/endpoint";

export class ProducerStub extends EndpointStub {
    protected getBindableEventNames(): string[] {
        return [
            "offerJob",
            "startJob",
            "cancelJob",
        ];
    }
        
    reportReady():Promise<void> {
        return this.invoke("reportReady");
    }

    requestJob(jobData:JobData):Promise<string> {
        // throw new Error("Method not implemented.");
        // console.log("Requesting",jobData);
        return this.invoke<string>("requestJob",jobData);
    }

    advertise(taskNames:string[]):Promise<void> {
        return this.invoke("advertise",taskNames);
    }

    acceptJob(jobId:string) {
        return this.invoke("acceptJob",jobId);
    }
    
    declineJob(jobId:string) {
        return this.invoke("declineJob",jobId);
    }

    updateJobStatus(data:JobStatusData) {
        return this.invoke("updateJobStatus",data);
    }

    finishJob(data:JobData) {
        return this.invoke("finishJob",data);
    }

    public cancelJob(jobId:string, reason:string) {
        let data = new JobCancelData({
            id:jobId,
            reason:reason
        });
        //this.emit("cancelJob", data);
        this.invoke("cancelJob", data);
    }

    // public remoteId:string = "Broker";

}