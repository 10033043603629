//import { platform } from '#@/utils/platform';
import {v4 as uuidV4} from 'uuid'
import { ArrayBufferConversions } from '#@/utils/conversion';



export class CustomId {
    //private _buffer: Buffer = Buffer.alloc(0);
    private _arrayBuffer: ArrayBuffer = new ArrayBuffer(0);

    private _fromString(data:string) {
        let s: string = data;
        s = s.replace(/-/gi, '+');
        s = s.replace(/_/gi, '/');
        //s = s.replace(/=/gi,'');
        //console.log(s)
        //this._buffer = Buffer.from(s, 'base64')
        this._arrayBuffer = ArrayBufferConversions.parse(s,"base64");
    }

    private _fromSize(size:number) {
        size = (size > 1) ? size : 1;
        this._arrayBuffer = new ArrayBuffer(size*16)//Buffer.alloc(size * 16);
        for (let i = 0; i < size; i++) {
            uuidV4(null, new Uint8Array(this._arrayBuffer), i * 16);
        }
    }

    constructor(size: number | string /*| Buffer*/ | CustomId | Uint8Array = 1) {
        if (typeof size === "string") {
            //console.log('from string')
            //let s: string = size;
            this._fromString(size);
            return;
        /*} else if (size instanceof Buffer) {
            this._buffer = size;
            return;*/
        } else if (size instanceof Uint8Array) {
            this._arrayBuffer = new Uint8Array(size).buffer//Buffer.from(size);
        } else if (size instanceof CustomId ) {
           this._fromString(size.asBased64()); 
        } else {
            this._fromSize(size);
        }
    }

    asUint8Array(): Uint8Array {
        return new Uint8Array(this._arrayBuffer);//Buffer.from(this._arrayBuffer);
    }

    /*get string(): string {
        let s: string = ArrayBufferConversions.toString(this._arrayBuffer,"base64");
        //console.log('...',s)
        s = s.replace(/\+/gi, '-');
        s = s.replace(/\//gi, '_');
        s = s.replace(/=/gi, '');
        return s;
    }*/

    asBased64(urlSafe:boolean=true) {
        let s: string = ArrayBufferConversions.toString(this._arrayBuffer,"base64");
        if (!urlSafe) return s;
        //console.log('...',s)
        s = s.replace(/\+/gi, '-');
        s = s.replace(/\//gi, '_');
        s = s.replace(/=/gi, '');
        return s;
    }

    asUuid(): string {
        return ArrayBufferConversions.toString(this._arrayBuffer,"hex").replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, '$1-$2-$3-$4-$5');
        //return this._buffer.toString('hex').replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, '$1-$2-$3-$4-$5')
    }

    /*async sign(other: CustomId): Promise<CustomId> {
        /*let hash: Hash = createHash('sha512');
        hash.update(this._buffer);
        hash.update(other._buffer);
        return new CustomId(hash.digest());s/
        return platform.sign(this,other);
    }*/

}


//export function idSign(...ids: CustomId[]): CustomId {
    /*let hash: Hash = createHash('sha512');
    let last: Buffer;
    ids.forEach((id, index) => {
        if (index > 1) {
            hash = createHash('sha512')
            hash.update(last)
        }
        hash.update(id.buffer)
        if (index >= 1) {
            last = hash.digest();
        }
    })
    return new CustomId(last!)*/
    //return platform.idSign(...ids);
//}

/*export function quickSign(tokenTag: string, secret: string) {
    var crypto = require('crypto');
    var hash = crypto.createHash('sha512');
    hash.update(tokenTag, "utf8");
    hash.update(secret);

    var b64 = hash.digest('base64');
    b64 = b64.replace(/\+/gi, '-');
    b64 = b64.replace(/\//gi, '_');
    b64 = b64.replace(/=/gi, '');
    return b64;
}*/

/*export function tagGet(slen:number, callback:(err: Error, salt: Buffer) => void) {
    crypto.randomBytes(slen,callback);
}*/
