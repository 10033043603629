import { Endpoint } from '#@client/taskRunner/endpoints/endpoint'
import { ProducerStub } from '#@client/taskRunner/rpcStubs/producer';
import { BaseJob } from '#@client/taskRunner/job/baseJob';
import { ManagedJob } from "#@client/taskRunner/job/managedJob";
import { JobData } from '#@/taskRunner/data/jobData';
import { Id } from '#@/collections/typeId';
import { Broker } from '#@client/taskRunner/rpcStubs/broker';
import { JobCancelData } from '#@/taskRunner/data/jobCancel';
import { JobHandler } from '#@client/taskRunner/job/jobHandler';
import { SingleJobFactory } from '#@client/taskRunner/job/singleJobFactory';
import { BaseJobHandler } from '#@client/taskRunner/job/baseJobHandler';
import { JobHandlerFactory } from '#@client/taskRunner/job/jobHandlerFactory';
import { Context } from '#@client/core/context';

export class Producer extends Endpoint {

    //#region Fields

    private advertisePending:boolean = false;
    private jobHandlerRegister: {[key:string]:BaseJobHandler} = {};
    private producerStub:ProducerStub|null=null;    
    private working:boolean=false;

    //#endregion Fields

    //#region Constructors

    public constructor(context:Context) {
        super(context);
    }

    //#endregion Constructors

    //#region Methods

    public acceptJob(job: BaseJob) {
        this.stubJobValidated((id,managedJob,stub)=>{ stub.acceptJob(id); }, job);
    }

    public advertise() {
        if (this.advertisePending) return;
        this.advertisePending = true;
        this.onceRegistered(()=>{
            if (this.producerStub===null) return this.error("producer stub not set");
            this.producerStub.advertise(Object.keys(this.jobHandlerRegister));
            this.advertisePending=false;
        });
    }

    public cancelJob(job:BaseJob, reason:string) {
        this.stubJobValidated((id,managedJob, stub)=>{
            stub.cancelJob(id,reason);
            this.manager.deleteJob(id);
            //this.working=false;
        },job);
    }

    public declineJob(job: BaseJob) {
        this.stubJobValidated((id,managedJob,stub)=>{ stub.declineJob(id); }, job);
    }

    public getJobHandler<T extends BaseJob>(factory:SingleJobFactory<T>, alias:string|null = null):JobHandler<T> {
        var name = alias;
        if (name === null || name=="") name = this.context!.getJobName(factory);
        //return new JobHandler<T>(c.name,c);//this.context.getJobName(
        if (this.jobHandlerRegister[name]) {
            return this.jobHandlerRegister[name] as JobHandler<T>;
        }
        return this.registerJob<T>(factory,name);//JobHandler<T>(name!,c);
    }

    public registerJob<T extends BaseJob>(factory:SingleJobFactory<T>, alias:string|null = null) {
        var name = alias;
        if (name===null || name=="") name = this.context!.getJobName(factory);
        let handler: JobHandler<T> =  this.onMakeJobHandler(factory, alias);
        this.jobHandlerRegister[name] = handler;
        this.advertise();
        return handler;
    }

    public reportReady() {
        this.onceRegistered(()=>{
            if (this.producerStub===null) return this.error("producer stub not set");
            this.producerStub.reportReady();
        });
    }

    public sendFinished(job: BaseJob) {
        this.stubJobValidated((id,managedJob, stub)=>{
            stub.finishJob(managedJob.job.asJobData());
            this.working=false;
        },job);
    }

    public sendStatus(progress: number | null, message: string | null, updateResponse: boolean | null, job: BaseJob) {
        this.stubJobValidated((id,managedJob, stub)=>{
            stub.updateJobStatus(managedJob.job.asJobStatusData(progress,message,updateResponse));
        },job);
    }

    public setStub(stub:ProducerStub) {
        super.setStub(stub);
        this.producerStub = stub;
        this.producerStub.on("offerJob",this.offerJobHandler);
        this.producerStub.on("startJob", this.startJobHandler);
        this.producerStub.on("cancelJob", this.cancelJobHandler);
    }
    
    protected onMakeJobHandler<T extends BaseJob>(factory:SingleJobFactory<T>, alias:string|null = null):JobHandler<T> {
        return new JobHandler<T>(factory,alias!);
    }

    protected onRegistered():void {
        this.advertise();
        if (!this.working) this.reportReady();
    }

    protected recreateStub(brokerStub:Broker):void {
        brokerStub.createProducer().then((c)=>{this.setStub(c);});
    }

    private cancelJobHandler = (cancelData:JobCancelData)=>{
        this.withManagedJob(cancelData.id,(managedJob)=>{
            this.eachHandlerJob((handler,job)=>{
                handler.invokeCancelJob(job,cancelData.reason??'',this);
            },managedJob.job);
            this.manager.deleteJob(managedJob.id);
        });
    }

    private eachHandler(action:(handler:BaseJobHandler)=>void):void {
        for (let key in this.jobHandlerRegister) {
            let handler = this.jobHandlerRegister[key];
            action(handler);
        }
    }

    private eachHandlerJob(action:(handler:BaseJobHandler,job:BaseJob)=>void, job:BaseJob):void {
        this.eachHandler((handler)=>{
            if (handler.canHandle(job)) action(handler,job);
        });
    }

    private guard(job:BaseJob):boolean {
        if (job.id===null) return false;
        if (this.producerStub===null) {this.error("producer stub not set");return false;}
        return true;
    }

    private offerJobHandler = (jobData:JobData)=>{
        let job = BaseJob.fromJobData(jobData);
        this.eachHandler((handler)=>{
            job = handler.castUp(job);
            this.manager.addUnmanagedJob(job);
        });
        let invoked:boolean = false;
        this.eachHandlerJob((handler,job)=>{
            invoked = invoked||handler.invokeConsiderJob(job,this);
        },job);
        if (!invoked) {
            //this.acceptJob(job);
        }
    }

    private startJobHandler = (jobId:Id) => {
        this.withManagedJob(jobId, (managedJob) =>
        {
            this.eachHandlerJob((handler, job) =>
            {
                handler.invokeStartJob(job, this);
            }, managedJob.job);
        });
    }    

    private stubJobValidated(action:(jobId:string,managedJob:ManagedJob,stub:ProducerStub)=>void,job:BaseJob):void {
        if (!this.guard(job)) return;        
        this.onceRegistered(()=>{
            this.withManagedJob(job.id,(managedJob)=>{
                if (!this.guard(managedJob.job)) return;
                managedJob.job.producer = this;
                action(managedJob.id!,managedJob,this.producerStub!);
            });
        });
    }

    private withManagedJob(id:string|null, action:(managedJob:ManagedJob)=>void):void {
        let tjob = this.manager.getJob(id)
        if (tjob===null) return;
        action(tjob);
    }

    //#endregion Methods
}