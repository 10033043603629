import { Endpoint } from '#@client/taskRunner/endpoints/endpoint'
import { ConsumerStub } from '#@client/taskRunner/rpcStubs/consumer';

import { BaseJob } from '#@client/taskRunner/job/baseJob';
//import { Endpoint } from "./endpoint";
import { ManagedJob } from "#@client/taskRunner/job/managedJob";
import { JobStatusEnum } from "#@client/taskRunner/job/jobStatusEnum";
import { Registry } from '#@/collections/registry';
import { JobData } from '#@/taskRunner/data/jobData';
import { Id } from '#@/collections/typeId';
import { Broker } from '#@client/taskRunner/rpcStubs/broker';
import { JobStatusData } from '#@/taskRunner/data/jobStatus';
import { JobCancelData } from '#@/taskRunner/data/jobCancel';
import { Context } from '#@client/core/context';
import { SingleJobFactory } from '#@client/taskRunner/job/singleJobFactory';

export class Consumer extends Endpoint {

    //#region Fields

    private consumerStub:ConsumerStub|null=null;
    private jobs:Registry<ManagedJob> = new Registry((item,nid)=>(nid)?item.id=nid:item.id);
    private pendingJobs:BaseJob[] = [];
    
    //#endregion Fields

    //#region Constructors

    public constructor(context:Context) {super(context);}

    //#endregion Constructors
    
    //#region Methods

    public cancelJob(job: BaseJob, reason: string):void {
        //throw new Error("Method not implemented.");
        if (job.id===null) return;
        this.onceRegistered(()=>{
            if (job.id===null) return;
            if (this.consumerStub===null) return this.error("Stub not set");
            this.consumerStub.cancelJob(job.id,reason);
        });
    }

    public createJob<T extends BaseJob>(factory:SingleJobFactory<T>,alias:string|null=null) {
        let newJob:BaseJob = factory.createJob();
        if (alias!==null && alias!=="") {
            newJob.taskName = alias;
        } else {
            newJob.taskName=this.context.getJobName(factory);
        }
        newJob.consumer = this;
        return newJob as T;
    }

    public requestJob(job: BaseJob) {
        if (!this.manager.hasJob(job.id)) {
            //this.manager.enqueueJob(job);
            this.pendingJobs.push(job);
            this.requestNew();
        }
        // throw new Error("Method not implemented.");
    }

    public setStub(stub:ConsumerStub) {
        super.setStub(stub);
        this.consumerStub = stub;
        this.consumerStub.on("updateJobStatus", this.updateStatusHandler);
        this.consumerStub.on("finishJob", this.finishJobHandler);
        this.consumerStub.on("cancelJob", this.cancelJobHandler);
    }

    protected recreateStub(brokerStub:Broker):void {
        brokerStub.createConsumer().then((c)=>{this.setStub(c);});
    }

    private cancelJobHandler = (data:JobCancelData)=>{
        // this.warn("Blah ",data);
        let managedJob = this.jobs.get(data.id);
        managedJob.job.cancelReceived(data.reason, this);
    }
    
    private finishJobHandler = (data:JobData) => {
        let managedJob = this.jobs.get(data.id);
        managedJob.job.loadRawResponseData(data.responseData??{});
        managedJob.job.emitFinished();
    }

    private requestNew() {
        this.onceRegistered(()=>{
            if (this.pendingJobs.length>0) {
                let job = this.pendingJobs.shift();
                this.sendJobRequest(job!);
            }
        });
    }

    private sendJobRequest(job:BaseJob) {
        if (this.consumerStub===null) return this.error("Stub not set");
        let mjob = new ManagedJob(job);
        mjob.status = JobStatusEnum.requesting;
        let j:JobData= mjob.job.asJobData();
        this.consumerStub.requestJob(j).then((newId:Id)=>{
            mjob.id = newId;
            this.jobs.register(mjob);            
        });
    }

    private updateStatusHandler=(data:JobStatusData) => {
        if (!this.jobs.contains(data.id)) return;
        let managedJob = this.jobs.get(data.id);
        let message = (data.message) || null;
        let progress = (data.progress) || null;
        let responseUpdated:boolean = false;
        if (data.responseData) {
            responseUpdated = true;
            managedJob.job.loadRawResponseData(data.responseData);
        }
        this.jobs.get(data.id).job.updateStatus(progress, message, responseUpdated);
    }

    //#endregion Methods
}