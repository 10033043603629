import { Stub } from "#@/rpcSystem/stubs/stub";
import { TrunkBinding } from '#@/socketIo/trunkBinding';
import { Registry } from '#@/collections/registry';
import { IListenerRegistration as ListenerRegistration } from '#@/rpcSystem/events/interfaces/listenerRegistration';

export abstract class RPCEndpoint<StubBaseType extends Stub/*<T>*/> extends TrunkBinding
{
    
    private _listenerRegistry: Registry<ListenerRegistration>
        = new Registry<ListenerRegistration>((lr,newid)=>{
            if (newid) lr.id=newid;
            return lr.id;
        })
    protected get listenerRegistry(): Registry<ListenerRegistration> { return this._listenerRegistry;}

    public createStub<StubType extends StubBaseType>(
        stubFactoryFunction:()=>StubType):StubType {
        const newStub=stubFactoryFunction();
        newStub.endpoint = this;
        //this._stubRegister.register(newStub);
        this.registerStub(newStub);
        return newStub;
    }

    protected abstract registerStub(stub:StubBaseType):void;
    protected abstract unregisterStub(stub:StubBaseType):void;

    public disposeStub(stub:StubBaseType) {
        //this.warn(`Disposing of ${stub.id} !!`);
        //this._stubRegister.remove(stub.stubName);
        this.unregisterStub(stub);
        stub.dispose();
    }
}