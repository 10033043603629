import { ComponentEventStruct, ComponentEventStructs, listener } 
    from "#@/eventSystem/componentEventStruct";
import { IBindable } from "#@/interfaces/bindable";
import { IEventData } from '#@/interfaces/eventData';
import { IListener } from "#@/interfaces/listener";
import { IdMapper } from "#@/collections/idMapper";
// import { Register as ObjectRegister } from "./collections/register";
import { Dictionary } from "#@/collections/dictionary";
import { WithId } from "#@/collections/withId";

type BindableWId = WithId<IBindable>;
type ListenerWId = WithId<IListener>;

export class RebindableEvents {

    private eventStructs:{[eventName:string]:ComponentEventStructs} = {};

    /*private listenerReg:ObjectRegister<Listener>
        = new ObjectRegister<Listener>();
    private targetReg:ObjectRegister<Bindable>
        = new ObjectRegister<Bindable>();
    private wrappedListeners:{[srcId:string]:{[targetId:string]:string}}
        = {};
    private targetRefCount:{[targetId:string]:number} = {};*/

    //private wrappedListeners:Dictionary<Listener,Dictionary<Bindable,Listener>>
    //    = new Dictionary<Listener,Dictionary<Bindable,Listener>>();
    private wrappedListeners:Dictionary<ListenerWId,Dictionary<BindableWId,IListener>>
        = new Dictionary<ListenerWId,Dictionary<BindableWId,IListener>>();

    private listenerIdMap:IdMapper<IListener> = new IdMapper();
    private bindableIdMap:IdMapper<IBindable> = new IdMapper();
    private bindableRefCount:{[id:string]:number} = {};
    //private wrappedLstener:{[srcId:string]:{[targetId:string]:Listener}} = {};

    private _targets: IBindable[] = [];

    public addTarget(target:IBindable) {
        if (target) {
        if ( this._targets.indexOf(target) === -1 ) {
            this._targets.push(target);
            this.bind(target);
        }
    }
        //return this._target;
    }

    public removeTarget(target:IBindable) {
        const index = this._targets.indexOf(target);
        if (index !== -1) {
            this._targets.splice(index,1);
            this.unbind(target);
        }
    }

    public eachTarget(callback:(target:IBindable)=>void) {
        for (const target of this._targets) {
            callback(target);
        }
    }

    private bindNew(eventName: string,struct: ComponentEventStruct) {
        this.eachTarget( (target)=> {
            this.attachOne(target,eventName,struct);
        })
    }

    public on(eventName:string, listener: listener, context?:any): void {
        let structs: ComponentEventStructs = this.eventStructs[eventName] 
            = (this.eventStructs[eventName])|| new ComponentEventStructs();
        this.eventStructs[eventName]=structs;
        this.bindNew(eventName, structs.addListener(listener, false, context));
    }

    public once(eventName: string, listener: listener, context?:any): void {
        let structs: ComponentEventStructs = this.eventStructs[eventName]
            = (this.eventStructs[eventName])|| new ComponentEventStructs();
        this.eventStructs[eventName]=structs;
        this.bindNew(eventName,structs.addListener(listener, true, context));
    }

    public removeListener(eventName: string, listener: listener, allowReattach: boolean = true): void {
        let structs: ComponentEventStructs;
        if (structs = this.eventStructs[eventName]) {
            if (structs.hasListener(listener)) {
                /* if (this._target) {
                    this._target.removeListener(eventName, listener)
                } */
                this.eachTarget( (target)=>{ target.off(eventName, listener); } );
                if (!allowReattach) {
                    structs.deleteListener(listener);
                }
            }
        }    
    }

    private wrapListener(rawTarget:IBindable, eventStruct:ComponentEventStruct)
        : IListener
    {
        let srcListener = this.listenerIdMap.map(eventStruct.listener);
        let target = this.bindableIdMap.map(rawTarget);
        let wl = this.wrappedListeners;
        if (
            wl.containsKey(srcListener) 
            && wl.getValue(srcListener)!.containsKey(target))
        {
            return wl.getValue(srcListener)!.getValue(target)!;
        }
        let listener:IListener = (...args:any[])=>{
            let eventData: IEventData = { context:eventStruct.context, source:target.object };
            args.splice(0,0,eventData)
            eventStruct.listener.apply(null,args);
        }
        if (!wl.containsKey(srcListener)) {
            wl.setValue(srcListener,new Dictionary<BindableWId,IListener>());
        }
        wl.getValue(srcListener)!.setValue(target,listener);
        if (this.bindableRefCount.hasOwnProperty(target.id)) {
            this.bindableRefCount[target.id]+=1;
        } else {
            this.bindableRefCount[target.id]=1;
        }
        return listener;
    }

    private getWrappedListener(
        rawTarget:IBindable, eventStruct:ComponentEventStruct): IListener | undefined
    {
        let srcListener = this.listenerIdMap.map(eventStruct.listener);
        let target = this.bindableIdMap.map(rawTarget);
        let wl = this.wrappedListeners;
        if (
            wl.containsKey(srcListener) 
            && wl.getValue(srcListener)!.containsKey(target))
        {
            return wl.getValue(srcListener)!.getValue(target)!;
        }
        return undefined;
    }

    private unwrapListener(rawTarget:IBindable, eventStruct:ComponentEventStruct)
        :void
    {
        let srcListener = this.listenerIdMap.map(eventStruct.listener);
        let target = this.bindableIdMap.map(rawTarget);
        let wl = this.wrappedListeners;
        if (!wl.containsKey(srcListener)) return;
        let byTarget = wl.getValue(srcListener);
        if (byTarget===undefined) return;
        if (!byTarget.containsKey(target)) return;
        byTarget.remove(target);
        if (this.bindableRefCount.hasOwnProperty(target.id)) {
            this.bindableRefCount[target.id]-=1;
            if (this.bindableRefCount[target.id]<=0) {
                delete this.bindableRefCount[target.id];
                this.bindableIdMap.unmapById(target.id);
            }
        }
        if (byTarget.size()===0) {
            wl.remove(srcListener);
            this.listenerIdMap.unmapById(srcListener.id);
        }
    }

    private attachOne(target:IBindable, eventName: string, eventStruct: ComponentEventStruct): void {
        /*let listener = (...args:any[])=>{
            let eventData: EventData = { context:eventStruct.context, source:target };
            args.splice(0,0,eventData)
            eventStruct.listener.apply(null,args);
        }*/
        let listener = this.wrapListener(target, eventStruct);
        if (eventStruct.once) {
            target.once(eventName, listener);
        } else {
            target.on(eventName, listener);
        }
    }

    private eachStruct(callback:(eventName:string, struct:ComponentEventStruct)=>void) {
        for (let name in this.eventStructs) {
            this.eventStructs[name].eachStruct((struct)=>{
                callback(name, struct);
            });
        }
    }

    private bind(target: IBindable) {
        // if (this._target) this.unbind();
        // this._target = target;
        this.eachStruct((name,struct)=>{
            this.attachOne(target,name,struct);
        });
    }

    private unbind(target:IBindable) {
        this.eachStruct((name, struct)=>{
            let listener = this.getWrappedListener(target, struct);
            if (listener===undefined) return;
            target.off(name, listener);
            this.unwrapListener(target,struct);
        });
    }

    public removeAll(): void {
        // this.unbind();
        this.eachTarget((target)=>{this.unbind(target);});
        for (let name in this.eventStructs) {
            this.eventStructs[name].deleteAll();
        }
        this.eventStructs = {};
    }

}
