//import {format as nformat} from 'util'
import { objFormat as nformat } from '#@/utils/inspection'
import { logArgument } /* as t_*/ from '#@/logging/types'
import { StackEntry } /** as i_*/ from '#@/logging/interfaces'
import { LogLevel } from '#@/logging/logLevel'
import { LogOptions } from '#@/logging/logOptions'

function getSubject(txt: string, len: number): string {
    if (len <= 3) return txt
    var m1l: RegExpMatchArray | null = txt.match(/([^\n]*)\n([\s\S]*)/)
    if (m1l) return m1l[1]
    return txt
    /*var m: RegExpMatchArray = txt.substr(0,len-2).match(/(.*)\s/)
    var os: string = ''
    //var ob: string = ''
    //console.log(m1l,m)
    if (m) {
      if (m[1].length>len-3) {
        m = txt.substr(0,len-3).match(/(.*)\s/)
      }
      os = m[1]
    } else {
      os = txt.substr(0,len-3)
    }
    /*if (os===m1l[1]) {
      ob = m1l[2]
    } else {
      ob = txt
    }
    return os+'...'*/
}

export class LogEntry extends LogOptions {

    time: Date
    level: LogLevel
    //subject: string
    //body: string | null
    data: logArgument[] | null
    route: string[] = []
    //function: string | null = null
    stackTrace: StackEntry[] | null = null
    //withStackTrace: boolean = false
    outputId: string | null = null

    clone(): LogEntry {
        let nle = new LogEntry()
        nle.time = this.time;
        nle.level = this.level;
        nle.subject = this.subject;
        nle.body = this.body;
        nle.data = this.data;
        nle.route = this.route;
        nle.function = this.function;
        nle.stackTrace = this.stackTrace;
        nle.withStackTrace = this.withStackTrace;
        nle.outputId = this.outputId;
        return nle;
    }

    private constructor(src?:LogEntry) {
        super();
        if (src) {
            this.time=new Date(src.time);
            this.level = src.level;
            this.subject = src.subject;
            this.body = src.body;
            this.data = src.data;
            this.route = (src.route)||[];
            this.function = src.function;
            this.stackTrace = src.stackTrace;
            this.withStackTrace = src.withStackTrace;
            this.outputId = src.outputId;
            return;
        }
        this.time = new Date()
        this.level = LogLevel.Debug
        this.subject = ''
        this.body = ''
        this.data = null

        //Object.assign(this, init);
        //this.trace = null
    }

    /**
     * Insert an entry into the LogEntries route array.
     * @param entry
     */
    addToRoute(entry: string) {
        this.route.splice(0, 0, entry);
    }

    private parseArgs(args: logArgument[]) {
        let pargs: logArgument[] = []
        //let pdata = []
        let opts: LogOptions | null = null
        args.forEach((arg: logArgument, idx: number) => {
            if (arg instanceof LogOptions) {
                opts = arg as LogOptions
                //console.log('!!',opts)
            } else {
                pargs.push(arg)
                //i/f (pargs.length>1) {
                //  pdata.push(arg)
                //}
            }
        })
        this.body = nformat(...pargs)//.apply(null, pargs)
        //this.subject = this.body
        if (this.body !== null) this.subject = getSubject(this.body, 80)
        if (this.subject !== this.body) this.subject += '...'
        //if (pargs.length>1) {
        this.data = pargs
        //} else {
        //  this.data = pargs[0]
        //}
        if (opts !== null) this.parseOptions(opts)
    }

    private parseOptions(options: LogOptions): LogEntry {
        //console.log('po', options)
        if (options.function) {
            //console.log(options)
            this.addToRoute(`fn:${options.function}`)
            this.function = options.function
        }
        if (options.body) {
            this.body = options.body
            this.subject = getSubject(this.body, 80)
            if (this.subject !== this.body) this.subject += '...'
        }
        if (options.subject) this.subject = options.subject
        if (options.withStackTrace) this.withStackTrace = options.withStackTrace
        return this
    }

    /**
     * Create a log entry from the supplied arguments.
     * 
     * If one of the arguments is a {@link LogOptions} object.
     * That objects values will take precidence
     * 
     * @param args 
     */
    public static FromArgs(args: logArgument[]):LogEntry {
        let newEntry = new LogEntry();
        newEntry.parseArgs(args);
        return newEntry;
    }

    public static Clone(other:LogEntry) {
        let nle = new LogEntry()
        //console.log(other.time);
        nle.time = new Date(other.time);
        nle.level = other.level;
        nle.subject = other.subject;
        nle.body = other.body;
        nle.data = other.data;
        nle.route = other.route;
        nle.function = other.function;
        nle.stackTrace = other.stackTrace;
        nle.withStackTrace = other.withStackTrace;
        nle.outputId = other.outputId;
        return nle;
    }

}
