import { GenericJobManager } from "#@/taskRunner/genericJobManager";
import { BaseJob } from "#@client/taskRunner/job/baseJob";
import { ManagedJob } from "#@client/taskRunner/job/managedJob";

export class JobManager extends GenericJobManager<ManagedJob> {

    public enqueueUnmanagedJob(baseJob:BaseJob):ManagedJob {
        let clientManagedJob:ManagedJob = new ManagedJob(baseJob);
        this.enqueueJob(clientManagedJob);
        return clientManagedJob;
    }

    public addUnmanagedJob(baseJob:BaseJob):ManagedJob {
        let clientManagedJob:ManagedJob = new ManagedJob(baseJob);
        this.addJob(clientManagedJob);
        return clientManagedJob;
    }
}
