import {IHasId} from "#@/collections/interfaces/hasId";

export class WithId<T> implements IHasId {
    id:string;
    object:T;

    constructor(id:string, object:T) {
        this.id = id;
        this.object = object;
    }

    toString():string {
        return `{id:${this.id},object:${this.object}}`
    }
}