import { generateId } from "#@/collections/util";

interface OneToOneIds 
{
    [id:string]:string;
}

interface OneToManyIds
{
    [id:string]:string[];
}

export class TwoWayOTMMap 
{
    private aToB:OneToManyIds = {};
    private bToA:OneToOneIds = {};

    public register(aId: string|undefined, bId:string|undefined):{aId:string,bId:string} {
        if ((aId===undefined) || (aId==='')) aId=generateId(this.aToB);
        if ((bId===undefined) || (bId==='')) bId=generateId(this.bToA);
        this.add(aId!,bId!);
        return {aId:aId!,bId:bId!}
    } 

    public add(aId: string, bId: string): void 
    {
        if (!this.hasA(aId)) {
            this.aToB[aId]=[];
        }
        if (this.aToB[aId].indexOf(bId)===-1)
        {
            this.aToB[aId].push(bId);
        }
        this.bToA[bId]=aId;
    }

    public hasA(aId:string):boolean 
    {
        return this.aToB.hasOwnProperty(aId);
    }

    public getByA(aId:string):string[] 
    {
        if (!this.hasA(aId)) return [];
        return this.aToB[aId].slice();
    }

    public removeByA(aId:string):void 
    {
        let remIds = this.getByA(aId);
        for (let bId of remIds) {
            delete this.bToA[bId];
        }
        delete this.aToB[aId];
    }

    public hasB(bId:string):boolean 
    {
        return this.bToA.hasOwnProperty(bId);
    }

    public getByB(bId:string):string 
    {
        return this.bToA[bId];
    }

    public removeByB(bId:string):void 
    {
        if (this.hasB(bId)) {
            let aId = this.getByB(bId);
            let bList = this.aToB[aId];
            bList.splice(bList.indexOf(bId),1);
            if (bList.length===0) this.removeByA(aId);
        }
    }

    public eachByA(aId:string, callback:(bId:string)=>void):void
    {
        if (!this.hasA(aId)) return;
        for (let bId of this.aToB[aId]) {
            callback(bId);
        }
    }

    public each(callback:(aId:string,bId:string)=>void):void 
    {
        for (let aId in this.aToB)
        {
            this.eachByA(aId,(bId)=>
            {
                callback(aId,bId);
            });
        }
    }
}