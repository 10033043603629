import { generateId } from '#@/collections/util';
import { IHasId } from '#@/collections/interfaces/hasId';
import { Id } from '#@/collections/typeId';

export class Registry<T/* extends IHasId*/> {
    private _items:{[id:string]:T} = {}

    constructor(private getSetId:(item:T,newId?:string)=>string|null) {

    }

    public register(item:T) {
        let itemId = this.getSetId(item);
        if ((itemId===null) || (itemId === "")) {
            itemId=generateId(this._items);//this.getSetId(item,generateId(this._items));
            this.getSetId(item,itemId);
        }
        this._items[itemId!] = item;
    }

    public contains(id:Id):boolean {
        if (id===null || id==="") return false;
        if (this._items[id]) return true;
        return false;
    }

    public get(id:Id):T {
        return this._items[id as string];
    }

    public each(callback:(entry:T, escape:()=>void)=>void) {
        let escaping:boolean = false;
        let escape = ()=>{ escaping = true; };
        for (let id in this._items) {
            callback(this._items[id], escape);
            if (escaping) {
                break;
            }
        }
    }

    public remove(id:Id) {
        delete this._items[id as string];
    }
}