import { LogEmitter } from "#@/logging/logEmitter";
import { ITrunk } from "#@/interfaces/trunk";
import { ITrunkSocket} from "#@/interfaces/trunkSocket";
import { TrunkState } from '#@/socketIo/enums/trunkState';

export class Trunk extends LogEmitter implements ITrunk {
    static bindSocket(socket: ITrunkSocket): Trunk {
        //throw new Error("Method not implemented.");
        let newTrunk=new Trunk();
        newTrunk.setSocket(socket);
        return newTrunk;
    }

    static unbound(): Trunk {
        return new Trunk();
    }
    
    private _socket:ITrunkSocket|undefined;
    //private _connected:boolean = false;
    private _state: TrunkState = TrunkState.NeverConnected;

    get trunkState():TrunkState { return this._state; }
    
    public get socket():ITrunkSocket|undefined { return this._socket; }
    public get connected():boolean { 
        //return this._socket.connected;
        return this.trunkState===TrunkState.Connected;
    }

    protected constructor(/*socket:ITrunkSocket*/) {
        super();
        //this._socket = socket;
        //this.bindEvents();
        //if (this._socket.connected) this._connectHandler();
    }
    
    public transmit<Out,In>(eventName: string, data: Out, callback?: ((cdata:In) => void) | undefined):void {

        if (!this._socket) return;
        if (!this._socket.open) return;
        this._socket.emit(eventName,data,(adata:any)=>{
            if (callback) callback(adata as In);
        });
        /*let args:[eventname:string, ...data:any[]] = [eventName, data];
        args.push((callbackData:CallbackData) => {
            if (callbackData.errMsg) {
                return invocation.reject(callbackData.errMsg);
            }
            invocation.resolve(callbackData.data);
        });
        if (this.lastTrunk) {
            let socket = this.lastTrunk.socket;
            if (socket) socket.emit.apply(socket, args);
        }*/
    }

    public open() {
        if (!this._socket) return;
        //this.warn("We have a socket!")
        if (this._socket.open) {
            /* console.log(this._socket);
            this._socket.on("connect", ()=>{
                this.warn("OCH!")
            }); */
            this._socket.open();
        }
    }

    public close() {
        if (!this._socket) return;
        if (this._socket.close) this._socket.close();
    }

    private bindEvents() {
        if (!this.socket) return;
        //this.warn("bind socket events")
        this.socket.on("connect", this._connectHandler);
        this.socket.on("connect_error", this._connectErrorHandler);
        this.socket.on("disconnect", this._disconnectHandler);
    }

    private unbindEvents() {
        if (!this.socket) return;
        this.socket.off("connect", this._connectHandler);
        this.socket.off("connect_error", this._connectErrorHandler);
        this.socket.off("disconnect", this._disconnectHandler);
    }

    protected setSocket(socket:ITrunkSocket) {
        this.unbindEvents();
        this._socket=socket;
        this._state=TrunkState.NeverConnected;
        this.bindEvents();
        if (this._socket.connected) this._connectHandler();
    }

    private _connectHandler = () => {
        //this.warn("ch!");
        switch (this._state) {
            case TrunkState.NeverConnected:
                this.debug("Trunk Connected [initial]")
                this._state = TrunkState.Connected;
                this.emit("connected",this);
                break;
            case TrunkState.Connected:
                this.debug("Trunk Connected [reconnect - never disconnected]");
                this.emit("reconnected",this,0);
                break;
            case TrunkState.Disconnected:
                this.debug("Trunk Connected [reconnect - disconnected]");
                this._state = TrunkState.Connected;
                this.emit("reconnected",this,0);
                break;
            default:
                this.debug("Handle",this._state);
        }
    }

    private _connectErrorHandler = (...args:any[])=> {
        this._state=TrunkState.Disconnected
        this.debug("Trunk Connect Error",...args);
        this.emit("connect_error",this,...args);
    }

    private _disconnectHandler = (reason:string) => {
        //this.debug("trunk disconnected", reason);
        this._state=TrunkState.Disconnected;
        this.emit("disconnected", this, reason);        
    }

    

    /*private _reconnectedHandler = () => {
        this.emit("reconnected", this);
    }*/
    //public abstract get socket():ITrunkSocket;
    //public abstract get connected():boolean;

}
