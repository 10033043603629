import { Id } from "#@/collections/typeId";
import { JobCancelData } from "#@/taskRunner/data/jobCancel";
import { JobData } from "#@/taskRunner/data/jobData";
import { EndpointStub } from "#@client/taskRunner/rpcStubs/endpoint";

export class ConsumerStub extends EndpointStub {
    protected getBindableEventNames(): string[] {
        return [
            "updateJobStatus",
            "finishJob",
            "cancelJob"
        ]    
    }

    requestJob(jobData:JobData):Promise<string> {
        return this.invoke("requestJob",jobData);
    }

    public cancelJob(jobId:string, reason:string) {
        let data = new JobCancelData({
            id:jobId,
            reason:reason
        });
        this.invoke("cancelJob", data);
    }

    // public remoteId:string = "Broker";
}