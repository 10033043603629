export interface IDictPair<K, V> {
    key:K,
    value:V
}

export class Dictionary<K extends Object,V> {
    setValue(key:K, value:V) {
        //throw new Error("Method not implemented.");
        this.items[key.toString()]={
            key:key,
            value:value
        }
    }
    getValue(key:K):V|undefined {
        const pair = this.items[key.toString()];
        if (pair) return pair.value;
        return undefined;
    }
    containsKey(key:K) {
        return (this.getValue(key)!==undefined);
    }
    remove(key:K) {
        delete this.items[key.toString()];
    }
    size():number {
        return Object.keys(this.items).length;
    }
    private items: { [key:string]:IDictPair<K,V>};

    constructor() {
        this.items = {};
    }
}