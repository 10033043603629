import { LogEmitter } from "#@/logging/logEmitter";
import { Trunk as BaseTrunk } from "#@/socketIo/trunk";
import { Client } from "#@client/core/client";
import sioClient, {Socket} from 'socket.io-client'

export class Trunk extends BaseTrunk {
    sioSocket: Socket|null;
    constructor(private client:Client) {
        super();
        this.sioSocket=null;
        client.auth.on("accessTokenUpdated",()=>{
            if (!this.sioSocket) return;
            this.sioSocket.auth={token:this.client.auth.accessToken};
        })
    }

    public open():void {
        this.debug("WS Open Trunk");
        //sioClient({autoConnect:false})
        this.sioSocket = sioClient(this.client.context.socketIoUrl.href,{autoConnect:false,auth:{token:this.client.auth.accessToken}});
        this.setSocket(this.sioSocket);
        super.open();
    }

    public close():void {
        this.debug("Close Trunk");
        super.close();
    }
}