import { Id } from '#@/collections/typeId';
//import { IJobReqResData } from '#@/taskRunner/data/jobReqRes';

export class JobStatusData {
    id:Id=null;
    message?:string;
    progress?:number;
    responseData?:object;

    constructor(initial?:Partial<JobStatusData>|null) {
        if (initial) Object.assign(this,initial);
    }
}