import { LogEmitter } from "#@/logging/logEmitter";
//import { ICredentials } from "#@/interfaces/credentials";
import { Client } from "#@client/core/client";
import { lookup } from "#@/utils/lookup";
import { CustomId } from "#@/utils/customId";
import { singlePromise } from "#@/utils/promises";
import { SignInRequest } from '#@/interfaces/authentication/signInRequest'
import { Credentials } from '#@client/authentication/credentials'
import { SignInResponse } from "#@/interfaces/authentication/signInResponse";
import { SignOutResponse } from "#@/interfaces/authentication/signOutResponse";
import { TokensData } from "#@/interfaces/authentication/tokensData";
import { TokenRefreshRequest } from "#@/interfaces/authentication/tokenRefreshRequest";
//import { Fetch } from "#@client/utils/fetch";

interface signOutResponse {
    authenticated:boolean;
}

interface signInResponse {
    error:{message?:string}[],
    auth?:{
        user?:{
            name:string
        }
    }
}

interface signInResults {}

enum signInState {
    unknown,
    signedIn,
    signedOut,
}

export class Authenticator extends LogEmitter {
    private state:signInState = signInState.unknown;
    private _username:string|undefined = '';
    accessToken: string='';
    refreshToken: string='';

    constructor(private client:Client) {
        super();
    }

    public get username():string {
        if (this.state==signInState.signedIn) return this._username??'';
        if (this.state==signInState.signedOut) return this._username??'';
        this.verify();
        const authG = window as {
            authorizedUser?:string;
        };
        if (authG.authorizedUser) return authG.authorizedUser;
        return '';
    }

    public get signedIn():boolean {
        if (this.state==signInState.signedIn) return true;
        if (this.state==signInState.signedOut) return false;
        this.verify();
        const authG = window as {
            authorizedUser?:string;
        };
        if (authG.authorizedUser) return true;
        return false;
    }

    public _verify=async ():Promise<boolean> => {
        let response = await this.client.fetch.getObject<SignInResponse>(this.client.context.signInUrl);
        if (!response?.auth?.user?.name) {
            this._username='';
            this.setState(signInState.signedOut);
            return false;
        }
        const username = response.auth.user.name;
        if (this._username!=username) {
            this._username = username;
            this.state = signInState.signedIn;
            this.emit("signedIn");
        }
        this.setState(signInState.signedIn);
        return true;
    }

    public async verify():Promise<boolean> {
        //await this.signInByRefreshToken();
        //return singlePromise(this._verify);
        return false;
    }

    public async signOut():Promise<void> {
        let response = await this.client.fetch.getObject<SignOutResponse>(this.client.context.signOutUrl)
        if (!response) return;
        if (response.authenticated) {
            this.setState(signInState.signedIn);
            return;
        }
        this._username='';
        this.setState(signInState.signedOut);
    }

    setState(newState: signInState) {
        if ( newState == this.state) return;
        const oldState = this.state;
        this.state = newState;
        if (newState == signInState.signedIn) return this.emit("signedIn");
        if (newState == signInState.signedOut && oldState!=signInState.unknown) {
            //console.trace('who done that?')
            return this.emit("signedOut");
        }
    }

    private async signInWithPassword(credentials:Credentials):Promise<SignInResponse>  {
        const data:SignInRequest = {
            username:credentials.username,
            password:credentials.password
        }
        const signInUrl:URL = this.client.context.signInUrl;
        //console.log("Signin",data,signInUrl);
        const response = await this.client.fetch.postObject<SignInRequest,SignInResponse>(signInUrl,data);
        if (!response) return { error:{messages:["an unknown error ocurred"]}}
        return response;
    }

    private async signInWithToken(credentials:Credentials):Promise<SignInResponse>  {
        //const tag = new CustomId();
        //const sig = await signAwithB(tag, new CustomId(credentials.tokenSecret));
        const data:SignInRequest = {
            token: (await credentials.generateTokenRequestData()).AsSignInRequestToken()
        }
        const signInUrl:URL = this.client.context.signInUrl;
        const response = await this.client.fetch.postObject<SignInRequest,SignInResponse>(signInUrl,data);
        if (!response) return { error:{messages:["an unknown error ocurred"]}}
        //console.log(response);
        return response;
        /*let response = (await this.client.webRequest.post('uac/login',{json:{
            tokenId:credentials.tokenId,
            tokenTag:tag.string,
            tokenSig:sig.string
        }}).json().catch((e)=>{})) as signInResponse
        if (response) {
            if (response.error.length>0) {
                this._username='';
                this.setState(signInState.signedOut);
                return response.error.map(e=>e.message??'');
            }
            this._username = response.auth?.user?.name;
            this.setState(signInState.signedIn)//auth.user.name
        }*/
        //return {};
    }

    private async attemptSignIn(credentials:Credentials):Promise<SignInResponse> {
        let errors = [];
        if (credentials.hasToken) {
            const tr= await this.signInWithToken(credentials);
            if (!tr.error) return tr;
            this._username = '';
            this.setState(signInState.signedOut);
            for (let m of tr.error.messages) {
                errors.push("Token: "+m);
            }
        }
        const pr= await this.signInWithPassword(credentials);
        if (!pr.error) return pr;
        this._username = '';
        this.setState(signInState.signedOut);
        if (credentials.hasToken) {
            for (let m of pr.error.messages) {
                errors.push("Password: "+m);
            }
            pr.error.messages=errors;
        }
        return pr;
    }

    private async signInWithCredentials(credentials:Credentials):Promise<SignInResponse> {
        const response = await this.attemptSignIn(credentials);
        if (response.error) {
            this._username = '';
            this.setState(signInState.signedOut);
            return response;
        }
        if (response.auth?.tokens) {
            this.handleNewTokenss(response.auth.tokens);
        }
        this._username=response.auth?.user?.name;
        this.setState(signInState.signedIn);
        return response;
    }

    handleNewTokenss(tokens: TokensData) {
        this.accessToken = tokens.access_token;
        this.emit("accessTokenUpdated");
        this.refreshToken = tokens.refresh_token;
        localStorage.setItem('refreshToken',this.refreshToken);
        setTimeout(()=>{this.refreshTokens()},(tokens.expires_in-2)*1000);
    }

    async refreshTokens():Promise<boolean> {
        //throw new Error("Method not implemented.");
        //console.log("perform token refresh")
        const response = await this.client.fetch.postObject<TokenRefreshRequest,SignInResponse>(
            this.client.context.tokenRefreshUrl,{token:this.refreshToken}
        );
        if (response?.auth?.tokens) {
            this.handleNewTokenss(response.auth.tokens);
            this._username=response.auth?.user?.name;
            this.setState(signInState.signedIn);
            return true;
        }
        return false;
        //console.log(response);

    }

    private async signInByRefreshToken():Promise<SignInResponse> {
        if (this.refreshToken == '') this.refreshToken = localStorage.getItem('refreshToken')??'';
        if (this.refreshToken == '') return <SignInResponse>{error:{messages:['No Refresh Token']}};
        if (await this.refreshTokens()) {
            return <SignInResponse>{auth:{user:{name:this._username}}}
        }
        return <SignInResponse>{error:{messages:['Token Refresh Failed']}};
    }

    public async signIn(credentials?:Credentials|string,password?:string):Promise<SignInResponse> {
        if (!credentials) {
            let c:Credentials|null = this.client.context.credentials;
            if (!(c?.password || c.tokenSecret)) {//return <SignInResponse>{
                let tokenBased = await this.signInByRefreshToken();
                if (!tokenBased.error) {
                    return tokenBased;
                }
                return <SignInResponse>{
                    error:{messages:[...tokenBased.error.messages,"No Credentials"]}
                }   
            }
            return this.signInWithCredentials(c);
        }
        if (typeof credentials=="string") return this.signInWithCredentials(new Credentials({username:credentials,password}));
        return this.signInWithCredentials(credentials);
        /*if (!credentials) {
            let tc:Credentials = lookup(this.core.config,'credentials')
            //console.log(tc);
            //return this.signIn2(this.core.options)
            if (tc) {
                return this.signIn(tc);
            }
            return [];
        }
        //return this.signInPassword(credentials);
        return this.signInToken(credentials);*/
    }
}