import { LogEmitter } from "#@/logging/logEmitter";
import { Producer } from "#@client/taskRunner/endpoints/producer";
import { BaseJob } from "./baseJob";

export abstract class BaseJobHandler extends LogEmitter {
    public abstract get taskName():string;
    public abstract castUp(job:BaseJob):BaseJob;
    public abstract canHandle(job:BaseJob):boolean;
    public abstract invokeConsiderJob(baseJob:BaseJob, producer:Producer):boolean;
    public abstract invokeStartJob(baseJob:BaseJob, producer:Producer):void;
    public abstract invokeCancelJob(baseJob:BaseJob, reason:string, producer:Producer):void;
}
