import { Broker } from "#@client/taskRunner/rpcStubs/broker";
import { AnonClientStub } from "#@/rpcSystem/stubs/anonClientStub";
import { IEndpointRegistration } from "#@/taskRunner/data/endpointRegistration";
import { Id } from "#@/collections/typeId";

export abstract class EndpointStub extends AnonClientStub {

    brokerStub: Broker|null=null;

    public register(id:IEndpointRegistration):Promise<Id> {
        return this.invoke("register", id);
    }

}