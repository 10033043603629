import { Credentials } from "#@client/authentication/credentials";
import init from "module-alias";

export class Config {
    public aliases: {[key: string]: string} = {};
    public apiRoutes: {[key: string]: string} = {};
    public baseUrl: string|null = null;
    public clientName: string|null = null;
    public configUrls: string[] = [];
    public credentials: Credentials|null = null;
    public dbgTag: string|null = null;
    public debug: boolean|null = null;
    public inCloudUrl: string|null = null;
    public overrideDebugDetection: boolean|null = null;
    public producerName: string|null = null;
    public taskNaming: string|null = null;

    constructor(initial?:Partial<Config>|null) {
        if (initial) Object.assign(this,initial);
        if (initial?.credentials) this.credentials = new Credentials(initial.credentials);
    }    
}