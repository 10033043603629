//import { functionName } from "#@/utils/inspection";
import { BaseJob } from "#@client/taskRunner/job/baseJob";
import { IOptions } from "#@client/core/options";
import { UrlUtils } from '#@client/utils/urlUtils';
import { Config } from "#@client/configuration/config";
import { Credentials } from '#@client/authentication/credentials';
import { IJobFactoryNames } from "#@client/taskRunner/job/interfaces/IJobFactoryNames";

/*class Options {
    public clientName = "{machineName}";
    public providerName = "Provider{.dbg}{.clientName}";
    public taskNaming = "{taskAlias}{.dbg}{?.clientName}";
    public dbgTag = "DBG";
    public aliases: {[key:string]:string} = {};
}*/

const DEFAULTCFG='icconfig.json';


export class Context {

    //#region Fields

    private _config:Config;
    private _credentials:Credentials = new Credentials();
    private _platformUrl:URL;
    private _signInUrl:URL;
    private _tokenRefreshUrl:URL;
    private _signOutUrl:URL;
    private _socketIoUrl:URL;

    //#endregion Fields

    //#region Constructors

    constructor(config:Config) {
        this._config = config;
        if (config.credentials!==null) this._credentials = Credentials.clone(config.credentials);
        this._platformUrl = this.urlRoute("");
        this._signInUrl = this.urlRoute("auth/signin");
        this._signOutUrl = this.urlRoute("auth/signout");
        this._tokenRefreshUrl = this.urlRoute("auth/tokenrefresh");
        this._socketIoUrl = this.urlRoute("socket.io");
    }

    //#endregion Constructors

    //#region Properties

    public get config():Config {return this._config;}
    public get credentials():Credentials {return this._credentials;}

    public get debug():boolean {
        if (this.config.overrideDebugDetection??false)
        {
            return this.config.debug??false;
        }
        return true; //TODO:Some form of environment/build debug detection
    }

    public get machineName():string {
        return "NOMACHINENAME"; //TODO:Some form of environment/build machine name detection perhaps from server?
    }

    public get platformUrl():URL {return this._platformUrl; }
    public get signInUrl():URL {return this._signInUrl; }
    public get tokenRefreshUrl():URL {return this._tokenRefreshUrl; }
    public get signOutUrl():URL {return this._signOutUrl; }
    public get socketIoUrl():URL {return this._socketIoUrl; }

    //#endregion Properties

    //#region Methods

    static GetDefaultConfigLocation(): string {
        return UrlUtils.absUrl(DEFAULTCFG).href;
    }

    getJobName(factoryNames:IJobFactoryNames): string {
        return this.expand(this.config.taskNaming??"", "taskNaming", {
            "className": factoryNames.className,
            "fullClassName": factoryNames.qualifiedName,
            "taskAlias": this.getJobNameAlias(factoryNames)
        });
    }

    public getJobNameAlias(factoryNames:IJobFactoryNames):string {
        let aliases = this.config.aliases;
        if (aliases[factoryNames.className]) return aliases[factoryNames.className];
        if (aliases[factoryNames.qualifiedName]) return aliases[factoryNames.qualifiedName];
        return factoryNames.qualifiedName;
    }
    
    public urlRoute(routeName:string):URL {
        if (!this.config.apiRoutes[routeName]) return UrlUtils.absUrl(this.config.inCloudUrl);
        return new URL(this.config.apiRoutes[routeName],UrlUtils.absUrl(this.config.inCloudUrl));
    }
    
    private expand(text:string, circ:string, replacements:{[key:string]:string}|null=null):string {
        let expandedText = text;
        let expansionRqd:boolean = true;
        while (expansionRqd) {
            expansionRqd = false;
            expandedText = expandedText.replace(/{(\?)?([^a-zA-Z0-9]?)([^}]*)}/g, 
                (substring:string, ...args:any[]):string => {
                    //console.log(substring,args);
                    let vname:string = args[2];
                    let separator:string = args[1];
                    let dbgOnly:boolean = args[0]==="?";
                    if (vname!==circ) {
                        let ov:string = "";
                        if ((!dbgOnly) || (this.debug)) {
                            switch (vname) {
                                case "machineName":
                                    ov = this.machineName;
                                    break;
                                case "dbg":
                                    ov = "";
                                    if (this.debug) {
                                        ov = this.config.dbgTag??"DBG";
                                    }
                                    break;
                                case "providerName":
                                    ov = this.config.producerName??"Producer{.dbg}{.clientName}";
                                    expansionRqd = true;
                                    break;
                                case "clientName":
                                    ov = this.config.clientName??"{machineName}";
                                    expansionRqd = true;
                                    break;
                                default:
                                    ov =  "UNKNOWN";
                                    if (replacements!==null) {
                                        if (replacements[vname]) {
                                            ov = replacements[vname];
                                        }
                                    }
                                    break;
                            }
                        }
                        if (ov==="") {
                            return ov;
                        } else {
                            return `${separator}${ov}`;
                        }
                    } else {
                        return `<${separator}${vname}:CircularReference>`
                    }
                });
        }
        return expandedText;
    }

    //#endregion Methods
}