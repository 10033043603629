import { SignInRequestToken } from "#@/interfaces/authentication/signInRequestToken";

export class TokenRequestData {
    AsSignInRequestToken(): SignInRequestToken {
        return {
            tokenId:this.tokenId,
            tag: this.tag,
            signature: this.signature
        }
    }
    public tokenId:string = '';
    public tag:string='';
    public signature:string='';
}