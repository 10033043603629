import { generateId } from '#@/collections/util';

interface OneToOneIds 
{
    [id:string]:string;
}

export class TwoWayMap 
{
    private aToB:OneToOneIds = {};
    private bToA:OneToOneIds = {};

    public add(aId: string, bId: string): void 
    {
        this.aToB[aId]=bId;
        this.bToA[bId]=aId;
    }

    public register(aId:string|undefined, bId: string|undefined):{aId:string,bId:string} {
        if ((aId===undefined) || (aId==='')) aId=generateId(this.aToB);
        if ((bId===undefined) || (bId==='')) bId=generateId(this.bToA);
        this.add(aId,bId);
        return {aId:aId!,bId:bId!}
    }

    private getByX(xId:string, xToY:OneToOneIds): string 
    {
        return xToY[xId];
    }

    private removeByX(xId:string,xToY:OneToOneIds,yToX:OneToOneIds)
    {
        let yId:string = this.getByX(xId, xToY);
        delete yToX[yId];
        delete xToY[xId];
    }

    public hasA(aId:string):boolean 
    {
        return this.aToB.hasOwnProperty(aId);
    }

    public getByA(aId:string):string 
    {
        return this.aToB[aId];
    }

    public removeByA(aId:string):void 
    {
        this.removeByX(aId,this.aToB,this.bToA);
    }

    public hasB(bId:string):boolean 
    {
        return this.bToA.hasOwnProperty(bId);
    }

    public getByB(bId:string):string 
    {
        return this.bToA[bId];
    }

    public removeByB(bId:string):void 
    {
        this.removeByX(bId,this.bToA,this.aToB);
    }

    public each(callback:(aId:string,bId:string)=>void):void 
    {
        for (let aId in this.aToB)
        {
            callback(aId,this.aToB[aId]);
        }
    }
}