import { IHasId } from "#@/collections/interfaces/hasId";
import { LogEmitter } from "#@/logging/logEmitter";
import { RPCEndpoint } from '#@/rpcSystem/endpoints/endpoint';

interface IStub {
    stubName:string;
}

export abstract class Stub/*<T>*/ extends LogEmitter 
{
    public endpoint:RPCEndpoint<Stub/*<T>*/>|null=null;
    public stubName:string;
    //public abstract stubName:string;
    //public persistent:boolean = false;
    //public closed: boolean = false;
    //public connected: boolean = false;
    public terminationReason: string|null = null;

    constructor() {
        super();
        this.stubName = this.initialStubName();
    }

    public abstract initialStubName(): string;

    public terminate(reason:string) 
    {
        this.terminationReason = reason;
        //this.warn(`Terminated: ${this.stubName}`);
        this.emit('terminated',reason);
    }

    public abstract invoke(methodName:string, ...data:any[]):Promise<any>;

    protected onDispose():void {}

    private disposed:boolean = false;
    public dispose() {
        if (this.disposed) return;
        try {
            this.onDispose();
        } catch (e:any) {
            if (e instanceof Error) {
                this.warn("trouble disposing",e.message);
            } else {
                this.warn("trouble disposing reason unknown");
            }
        }
        this.disposed=true;
        //this.warn(`Disposed: ${this.stubName}`);
        if (this.endpoint!==null) this.endpoint.disposeStub(this);
    }
}


