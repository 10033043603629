export class UrlUtils {
    static traverseUp(url: URL): URL {
        return new URL(url.pathname.substr(url.pathname.lastIndexOf('/')),url);
    }
    public static absUrl(src:string|null):URL {
        let url:URL;
        try {
            url = new URL(src??"/");
        } catch (e) {
            if (typeof window == 'undefined') throw new Error(`Unable to create valid url for "${src}" (no global window obect)`);
            try {
                url = new URL(src??"/",window.location.href);
            } catch (e) {
                throw new Error(`Unable to create valid url for "${src}" (${e})`);
            }
        }
        return url;
    }
}