import { ClientStub } from "#@/rpcSystem/stubs/clientStub";
import { ConsumerStub } from "#@client/taskRunner/rpcStubs/consumer";
import { ProducerStub } from "#@client/taskRunner/rpcStubs/producer";
export class Broker extends ClientStub {
    protected getBindableEventNames(): string[] {
        return [
            "updateEndpoints",
            "reportJobMonitorStatus"
        ];
    }
    public initialStubName(): string {
        return "Broker";
    }
    
    async createConsumer():Promise<ConsumerStub> {
        //this.warn("Broker Stub Creating Consumer")
        const newStub=await this.client.pendingIdToStub(this.invoke<string>("createConsumer"), ()=>new ConsumerStub())
        //this.warn("Broker Stub Creating Consumer2")
        newStub.brokerStub = this;
        return newStub;
    }

    async createProducer():Promise<ProducerStub> {
        const newStub=await this.client.pendingIdToStub(this.invoke<string>("createProducer"), ()=>new ProducerStub())
        newStub.brokerStub = this;
        return newStub;
    }
    
}