export type listener = (...args:any[])=>void;

export class ComponentEventStruct {
    public listener: listener=()=>{};
    // public eventName:string;
    public once:boolean = false;
    context: any;
    // public authenticate:boolean = false;
}

export class ComponentEventStructs {
    private structs:ComponentEventStruct[] = [];

    public addListener(listener:listener, once:boolean, context?:any):ComponentEventStruct {//boolean {
        for (let i=0;i<this.structs.length;i++) {
            let struct = this.structs[i];
            if (struct.listener===listener) {
                if (struct.once!==once) {
                    struct.once = once;
                }
                return struct;
            }
        }
        let ns = new ComponentEventStruct();
        ns.listener = listener;
        ns.once = once;
        ns.context = context;
        // ns.authenticate = authenticate;
        this.structs.push(ns);
        return ns;
    }

    public hasListener(listener:listener):boolean {
        for (let i=0;i<this.structs.length;i++) {
            //let struct = this.structs[i];
            if (this.structs[i].listener === listener) return true;
        }
        return false;
    }

    public deleteListener(listener:listener): boolean {
        for (let i=this.structs.length-1;i>=0;i--) {
            if (this.structs[i].listener === listener) {
                this.structs.splice(i,1);
                return true;
            }
        }
        return false;
    }

    public eachStruct(callback:(struct:ComponentEventStruct)=>void) {
        for (let i=0;i<this.structs.length;i++) {
            callback(this.structs[i]);
        }
    };

    public deleteAll():void {
        this.structs = [];
    }

}
