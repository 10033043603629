import { EventEmitter } from "events";
import { logArgument } from "#@/logging/types";
import { LogEntry } from "#@/logging/logEntry";
import { log } from "#@/logging/index";
import { LogLevel } from "#@/logging/logLevel";
import { StackEntry } from "#@/logging/interfaces";
import { logOutputs } from "#@/logging/logOutputs"

function functionName(afunction: ((...args:any[])=>any)|Function): string {
    if ((afunction as any).name) return (afunction as any).name as string;
    //console.log("!!!!!!!",afunction.toString());
    let fnre = /^(function|class)\s+([^\t ({\n]*)\s*/
    let m: RegExpMatchArray | null = afunction.toString().match(fnre)
    if (m === null) return '[anonfunc]'
    return m[2];
}

/**
 * Parses a stack trace string into an array of StackEntry
 @param trace The stack trace to parse`
 @return An array of StackEntry
 */
 function parseTrace(trace: string | undefined): StackEntry[] {
    let stack: StackEntry[] = [];
    if (trace === undefined) return stack;
    let myRe: RegExp = /\s*at(\s(.*)\s+\()?\s*(.*):(\d*):(\d*)/g;
    let myArray: RegExpExecArray | null;
    while ((myArray = myRe.exec(trace)) !== null) {
        stack.push(
            {
                method: myArray[2],
                module: myArray[3],
                line: myArray[4],
                column: myArray[5]
            }
        );
    }
    for (let i = stack.length - 1; i >= 0; i--) {
        //console.log(stack[i].method)
        if (stack[i].method && stack[i].method.match(/^LogEmitter\./)) {
            stack = stack.slice(i + 1)
            break;
        }
    }
    return stack;
}

/**
 * Get a stack trace by gracefully raising an error and parsing it's stack trace
 limited to specified depth
 @param limit depth of stack to return
 @return An array of StackEntry
 */
 function getStackTrace(limit: number = Infinity): StackEntry[] {
    let defaultLimit = Error.stackTraceLimit
    Error.stackTraceLimit = Infinity
    let stack: StackEntry[] | undefined = parseTrace(new Error().stack).slice(1, limit)
    Error.stackTraceLimit = defaultLimit
    return stack
}

/**
 * Base class for anything that might want to emit logs, or fire events
 */
export class LogEmitter extends EventEmitter {

    protected _emitLog(entry: LogEntry): void {
        if (this.listenerCount('log') > 0) {
            this.emit('log', entry)
        }
        //console.log(entry)
        logOutputs.log(entry)
    }

    private _makeEntry(restData: logArgument[], level: LogLevel, stackTrace: Boolean = false): LogEntry {
        let entry: LogEntry = LogEntry.FromArgs(restData);
        entry.addToRoute(this.getLogEmitterName())
        entry.level = level
        if ((entry.data !== null) && (entry.data.length === 1) && (typeof entry.data[0] === 'string') && (entry.data[0] === entry.body)) entry.data = null
        if (entry.subject === entry.body) entry.body = null
        if (stackTrace) entry.withStackTrace = true
        if (entry.withStackTrace) entry.stackTrace = getStackTrace()
        return entry
    }

    /**
     * Emit a Fatal level Log Entry
     * @param rest Arguments to turn into a LogEntry
     */
    fatal(...rest: logArgument[]) {
        this._emitLog(this._makeEntry(rest,LogLevel.Fatal,true))
    }

    /**
     * Assert that `value` is true otherwise emit an Error level Log Entry
     * @param value Value to test
     * @param rest Arguments to turn into a LogEntry
     */
    assert(value: any, ...rest: logArgument[]) {
        if (!value) {
            let entry = this._makeEntry(rest, LogLevel.Error, true);
            entry.subject = `Assertion failed: ${entry.subject}`
            this._emitLog(entry);
        }
    }

    /**
     * Emit an Error level Log Entry
     * @param rest Arguments to turn into a LogEntry
     */
    error(...rest: logArgument[]) {
        this._emitLog(this._makeEntry(rest, LogLevel.Error, true))
    }

    /**
     * Emit a Warning level Log Entry
     * @param rest Arguments to turn into a LogEntry
     */
     warn(...rest: logArgument[]) {
        this._emitLog(this._makeEntry(rest, LogLevel.Warn))
    }

    /**
     * Emit an Info level Log Entry
     * @param rest Arguments to turn into a LogEntry
     */
    info(...rest: logArgument[]) {
        this._emitLog(this._makeEntry(rest,LogLevel.Info));
    }

    /**
     * Emit a Debug level Log Entry
     * @param rest Arguments to turn into a LogEntry
     */
     debug(...rest: logArgument[]) {
        this._emitLog(this._makeEntry(rest, LogLevel.Debug))
    }

    /**
     * Emit a Trace level Log Entry
     * @param rest Arguments to turn into a LogEntry
     */
     trace(...rest: logArgument[]) {
        this._emitLog(this._makeEntry(rest, LogLevel.Trace))
    }

    private getLogEmitterName(): string {
        let ref
        //console.log('WORK THIS',this.constructor.name)
        return functionName(this.constructor) + (this.instanceName ? `(${this.instanceName})` : '');
    };

    fullStopCatch(...rest:logArgument[]) {
        const catcher = (e:any)=>{
            if (e instanceof Error) {
                let er = e as Error;
                e = {internalError:{
                    name:er.name,
                    message:er.message,
                    stack:er.stack
                }}
            }
            this.fatal(...rest,e);
            process.exit(1);
        }
        return catcher;
    }    

    errorCatch(...rest:logArgument[]) {
        const catcher = (e:any)=>{
            this.error(...rest,e);
        }
        return catcher;
    }

    private _instanceName: string | null = null
    /**
     * Set this to allow for more granular logging e.g. ClassName(instanceName) in log routes.
     */
    get instanceName(): string | null { return this._instanceName }
    set instanceName(value: string | null) { this._instanceName = value }

    private static _general:LogEmitter|null = null;
    public static get general():LogEmitter {
        if (LogEmitter._general!=null) return LogEmitter._general;
        LogEmitter._general = new LogEmitter();
        LogEmitter._general.instanceName="General";
        return LogEmitter._general;
    }

}