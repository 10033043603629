import { WithId } from "#@/collections/withId";
import { generateId } from "#@/collections/util";

export class IdMapper<T>
{
    private byIds:{[id:string]:WithId<T>} = {};

    public findId(object:T):string|undefined {
        let ids = Object.keys(this.byIds)
        for (let id of ids) {
            if (this.byIds[id].object === object) {
                return id;
            }
        }
        return undefined;
    }
    
    public map(object:T):WithId<T> {
        let id = this.findId(object);
        if (!id) {
            id = generateId(this.byIds);
            this.byIds[id] = new WithId<T>(id,object);
        }
        return this.byIds[id];
    }

    public unmapByObject(object:T):void {
        let id = this.findId(object);
        if (id) { this.unmapById(id); }
    }

    public unmapById(id:string):void {
        delete (this.byIds[id]);
    }
}