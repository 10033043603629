/*export function functionName(afunction: ((...args:any[])=>any)|Function): string {
    if ((afunction as any).name) console.log("hasname",(afunction as any).name); 
    console.log("!!!!!!!",afunction.toString());
    if ((afunction as any).name) return (afunction as any).name as string;
    console.log("!!!!!!!",afunction.toString());
    let fnre = /^(function|class)\s+([^\t ({\n]*)\ss/
    let m: RegExpMatchArray | null = afunction.toString().match(fnre)
    if (m === null) return '[anonfunc]'
    return m[2];
}*/

export function circleBreak(obj:any,path:string='obj',hash:WeakMap<any,string> = new WeakMap()):any {
    if (obj === undefined) return {$undefined:null}
    if (Object(obj)!==obj||obj instanceof Function) return obj;
    if (hash.has(obj)) return {$circRef:hash.get(obj)};
    hash.set(obj,path);
    if (obj instanceof Array) {
      //console.log(path,"array?")
      return Array.from(obj,(v,k)=>circleBreak(v,`${path}[${k}]`,hash))
    } 
    return Object.assign({},...Object.keys(obj).map(
      key => ( {
        [key]:circleBreak(obj[key],`${path}.${key}`,hash)
      })
    ))
  }
  
export function objFormat(...objs:any[]):string {
    return objs.map(o=>
      JSON.stringify(circleBreak(o))
      .replace(/^"(.*)"$/,'$1')
      .replace(/\{\s*"\$undefined"[^}]*\}/g,'undefined')
    ).join(", ");
}