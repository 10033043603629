//import { functionName } from "#@/utils/inspection";
import { IJobFactoryNames } from "#@client/taskRunner/job/interfaces/IJobFactoryNames";
import { BaseJob } from "#@client/taskRunner/job/baseJob";

export class SingleJobFactory<T extends BaseJob> implements IJobFactoryNames {
    private _createJob:()=>T;
    private _jobClassName:string;
    private _jobFQName:string;
    
    public get className():string {return this._jobClassName;}
    public get qualifiedName():string {return this._jobFQName;}
    public get createJob():()=>T { return this._createJob;}

    public constructor(createFunction:()=>T) {
        this._jobClassName = createFunction().defaultTaskName;
        this._createJob = ()=>{
            let nj = createFunction();
            nj.initialise();
            return nj;
        }
        this._jobFQName = this._jobClassName;
    }
}