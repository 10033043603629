import { BaseJob } from "#@client/taskRunner/job/baseJob";
import { BaseData } from "#@client/taskRunner/job/data/baseData";

export abstract class GenericBaseJob
    <jobType extends BaseJob
    ,reqType extends BaseData
    ,resType extends BaseData> extends BaseJob {
    
    //#region Fields

    private _requestData!:reqType;
    private _responseData!:resType;

    //#endregion Fields

    //#region Constructors

    public constructor() {
        super();
        //this._requestData = this.initialiseRequestData();
        //this._responseData = this.initialiseResponseData();
    }

    //#endregion Constructors

    //#region Properties

    public get requestData():reqType {return this._requestData;}
    public set requestData(data:reqType) {this._requestData=data;}
    public get responseData():resType {return this._responseData;}
    public set responseData(data:resType) {this._responseData=data;}

    //#endregion Properties

    //#region Methods

    protected abstract getDefaultTaskName():string;

    public initialise():void {
        this._requestData = this.initialiseRequestData();
        this._responseData = this.initialiseResponseData();
    }

    protected abstract initialiseRequestData():reqType;
    protected abstract initialiseResponseData():resType;

    protected onEmitCancelled(reason:string): void
    {
        this.emit("cancelled", reason, this as unknown as jobType);
    }

    protected onEmitFinished(): void
    {
        this.emit("finished", this as unknown as jobType);
    }

    protected onEmitProgressChanged(value:number): void
    {
        this.emit("progressChanged", value, this as unknown as jobType);
    }

    protected onEmitResultsChanged(): void
    {
        this.emit("resultsChanged", this as unknown as jobType);
    }

    protected onEmitStatusMessageChanged(message:string): void
    {
        this.emit("statusMessageChanged", message, this as unknown as jobType);
    }

    protected onRawDataUpdated():void {
        if (this._rawData.requestData) this._requestData=this._rawData.requestData as reqType;
        if (this._rawData.responseData) this._responseData=this._rawData.responseData as resType;
    }

    protected onUpdateRawData():void {
        this._rawData.requestData=this._requestData;
        this._rawData.responseData=this._responseData;
    }

    //#endregion Methods
}